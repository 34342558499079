import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { UAParser } from "ua-parser-js";
import { WalletConnect } from "../libs/wc2";
import config from "../helpers/config";

export const supportedChainIds = [1, 56, 97];

export const RPC = {
  56: "https://bsc-dataseed.binance.org/",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
};

export const [metaMask, metaMaskHooks] = initializeConnector(
  actions => new MetaMask({ actions })
);

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector(
  actions =>
    new WalletConnect({
      actions,
      options: {
        projectId: "3f88e94898fd5cef96179661bd9e3a49",
        chains: [config.allowedChainId],
        optionalChains: [config.allowedChainId],
        showQrModal: true
      }
    })
);

const UA =
  typeof window !== "undefined"
    ? new UAParser(navigator.userAgent)
    : new UAParser("");

export const WALLETS = [
  {
    title: "Trust Wallet",
    subtitle: "DApp in app",
    connector: metaMask,
    icon: "/images/wallet/TW.png"
  },
  {
    title: "TokenPocket",
    subtitle: "DApp in app",
    connector: metaMask,
    icon: "/images/wallet/TP.png"
  },
  {
    title: "MetaMask",
    subtitle: "Desktop / DApp in app",
    connector: metaMask,
    icon: "/images/wallet/MT.png"
  },
  {
    title: "WalletConnect",
    subtitle: "Any wallet and browser",
    connector: walletConnectV2,
    icon: "/images/wallet/WC.png",
    disabled:
      (UA.getBrowser().name === "Chrome WebView" ||
        UA.getBrowser().name === "Chrome") &&
      parseInt(UA?.getBrowser?.()?.major) <= 87
  }
];
