import React from "react";

export const MemecoinBanner = () => {
    return (
        <div className="w-full max-w-[1500px] px-[108px] sm:px-4">
            <div className="flex flex justify-between items-center w-full mt-[180px] bg-[#0F1011] rounded-[30px] relative overflow-hidden sm:flex-col-reverse sm:px-4 sm:mt-[80px] ">
                <div className="flex flex-col space-y-5 w-full pl-[60px] py-[140px] sm:py-[32px] sm:pl-0 sm:items-center z-[11] sm:h-[564px]">
                    <span className="text-[50px] text-white font-bold uppercase leading-[60px] sm:text-2xl sm:text-center">Memecoin <br className="sm:hidden" />Launch<br />Made Easy</span>
                    <div className="flex flex-col space-y-8 z-[1]">
                        <span className="text-white-500 font-light inter sm:text-sm sm:text-center">FRGX Launchpad empowers projects to launch memecoins with<br className="sm:hidden" /> presale and whitelist options exclusively for FRGX and BASE<br className="sm:hidden"/> token holders.</span>
                        {/* <button className="flex items-center justify-center w-[185px] h-[52px] bg-white rounded-2xl sm:w-full sm:h-[48px]">
                            <span className="text-xl text-black font-medium sm:text-sm">Explore</span>
                        </button> */}
                    </div>
                </div>
                <img className="z-[1] absolute bottom-0 right-0" src="/images/memecoinBanner/rocket.png"/>
                <img className="absolute top-0 left-0 w-full z-[0] sm:h-full " src="/images/memecoinBanner/shadow.png"/>
                <img className="absolute top-0 w-[476px] left-[300px] w-full z-[11] sm:h-full sm:hidden" src="/images/memecoinBanner/stars.png"/>
            </div>
        </div>
    )
}