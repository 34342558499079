import React, { useMemo, useState } from "react";


export const RoadMap = () => {

  const roadMapList = [
    { 
      content: [
        {
          title: 'Deployment on BASE Blockchain',
          desc: "FRGX is being deployed on BASE, a layer-2 scaling solution for Ethereum, offering faster and cheaper transactions. This allows to leverage BASE's benefits, making the token more accessible and efficient for users.",
        }
      ],
    },
    {
      content: [
        {
          title: 'Memecoin Launchpad',
          desc: 'Memecoin Launchpad provides a dedicated platform for projects to launch their memecoins with ease. Exclusive access to presales and whitelists is reserved for holders of the FRGX Base token.',
        }
      ],
    },
    {
      content: [
        {
          title: 'Listing on a new CEX',
          desc: "Listing on a new centralized exchange increases the token's accessibility and liquidity by enabling users to buy, sell, and trade tokens on the exchange's platform. This boosts FRGX adoption and price.",
        }
      ],
    },       
  ]

  const [currentTab, setCurrentTab] = useState(0);

    return (
      <div className="flex items-center justify-center w-full relative">
        <div className="w-full max-w-[1500px] px-[108px] sm:px-0 relative">
              <div className="relative flex flex-col items-start space-y-[60px] relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] mt-[216px] sm:mt-[80px] sm:items-center sm:space-y-10 ">
                  <h2 className="text-[50px] poppins text-white font-semibold uppercase sm:text-[30px] sm:text-center">our roadmap</h2>
                      <div className="flex flex-col w-full rounded-[30px] bg-white-20 h-[464px] relative overflow-hidden sm:h-[360px] sm:rounded-3xl">
                        <div className="flex flex-col w-full h-full z-[1] pt-[120px] space-y-[60px] sm:pt-[60px] sm:space-y-10">
                              <div className="w-full flex items-center  sm:px-4 sm:overflow-auto ">
                                <div className="flex jsutify-between w-full">
                                  {roadMapList.map((item, itemIndex) => {
                                    const isActive = itemIndex === currentTab;
                                    return (
                                    <div className="flex justify-center items-center w-full " key={itemIndex}>
                                      <div className="flex flex-col justify-center items-center space-y-5 sm:space-x- sm:px-0">
                                        <span className={`text-xl inter font-light ${isActive ? "!text-white" : "!text-white-200"}`}>
                                          0{itemIndex + 1}
                                        </span>
                                        <button onClick={() => {setCurrentTab(itemIndex)}} className={`rounded-full w-8 h-8 border border-[1px] border-white-500 backdrop-blur-[5px] z-[111] ${isActive ? "!bg-white" : "!bg-gray-60"} `} />
                                      </div>
                                    </div>
                                    )
                                    })}
                                  </div>
                                <div className="absolute mt-[47px] left-0 w-full h-[2px] bg-gray-200" />
                              </div>

                           {roadMapList[currentTab].content?.map((item, itemIndex) => {
                              return (  
                                  <div className="flex space-x-5 px-12 inter sm:flex-col sm:space-x-0 sm:px-4 sm:space-y-3">
                                    <span className="max-w-[300px] text-2xl text-white font-medium leading-[36.6px] sm:text-lg">{item?.title}</span>
                                    <span className="max-w-[430px] text-white-500 font-light leading-7 sm:text-sm">{item?.desc}</span>
                                  </div>
                                  )
                              })}
                        </div>
                        <img className="sm:hidden absolute left-0 h-full w-full" src="/images/roadMap/leftShadow.png"/>
                        <img className="hidden sm:flex absolute left-0 h-full" src="/images/roadMap/leftShadowMob.png"/>
                      </div>
                  </div>  
              </div>
          <img className="left-0 absolute top-20" src="/images/roadMap/shadow.png"/>
        </div>
    )
}
