import React from "react";
import Countdown from 'react-countdown';
import { SWAP_TOKEN_START } from "../../helpers/constants";

export const Timer = ({ isCompletedCountdown, setIsCompletedCountdown, customDate = null  }) => {

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let finalDays = days < 10 ? `0${days}` : days;
    let finalHours = hours < 10 ? `0${hours}` : hours;
    let finalMin = minutes < 10 ? `0${minutes}` : minutes;
    if (completed) {
      return null;
    } else if (days > 0) {
      return (
        <span>
          {finalDays}d {finalHours}h {finalMin}m
        </span>
      );
    } else if (days === 0 && hours >= 1) {
      return (
        <span>
          {finalHours} h {finalMin} m {seconds} s
        </span>
      );
    } else if (days === 0 && hours === 0) {
      return (
        <span>
          {finalMin} m {seconds} s
        </span>
      );
    }

    return (
      <span>
        {finalHours} h {finalMin} m {seconds} s
      </span>
    );
  };

  if (!isCompletedCountdown) {
    return (
       <Countdown
          onComplete={() => setIsCompletedCountdown(true)}
          date={customDate || SWAP_TOKEN_START}
          renderer={renderer}
        />
    )
  }
  return null;
  
}