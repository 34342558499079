import React, {useEffect, useState} from "react";
import { Modal } from "../../Modal";
import { callNotification } from "../../../helpers/notification";
import { useCallTransaction } from "../../../helpers/hooks/useCallTransaction";
import { useRegistration } from "../../../helpers/hooks/useRegistration";
import { useLocation } from "react-router-dom";
import { parseErrorToUserReadableMessage } from "../../../helpers/format";
import { CONTRACT_NAMES, MAX_VALUE } from "../../../helpers/constants";
import config from "../../../helpers/config";
import { toWei } from "../../../helpers/numbers";
import { useGetContract } from "../../../helpers/hooks/useGetContract";
import { useWeb3React} from "@web3-react/core";
import { useBalance } from "../../../helpers/hooks/useBalance";

export const RegistrationModal = ({ openedModal, handleCloseModal, isRegistered, contractsFetch }) => {
    const [isNeedApprove, setIsNeedApprove] = useState(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState(false);
    const [isWaitingApprove, setIsWaitingApprove] = useState(false);
    const { account } = useWeb3React();
    const { search } = useLocation();
    const { onCallTransaction, transactionInfo } = useCallTransaction();
    const { registration } = useRegistration();
    const { getContract } = useGetContract();
    const {
        balanceBusd = 0,
        fetchBalance
    } = useBalance({ contractsFetch, withoutFixed: true });

    useEffect(() => {
        if (account) {
            fetchBalance();
        }
    }, [account, contractsFetch]);

    const uplineId = search.split('=')[1] || 1;
    const REGISTRATION_PRICE = 10;

    const onRegistration = async () => {
        try {
            const result = await registration(uplineId);

            onCallTransaction(result);
        } catch (e) {
            callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
        }
    }

    const checkApprove = async () => {
        const MIN_BALANCE = parseInt(MAX_VALUE, 16);
        try {
            const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
            const approveBalance = await contractBusd.allowance(
              account,
              config.contractRouter
            );

            const isAllowed = REGISTRATION_PRICE
              ? parseInt(approveBalance) >= parseInt(toWei(REGISTRATION_PRICE))
              : approveBalance >= MIN_BALANCE;

            if (isAllowed) {
                setIsNeedApprove(false);
            } else {
                setIsNeedApprove(true);
            }
        } catch (e) {
            setIsNeedApprove(true);
        }
    };

    useEffect(() => {
        checkApprove();
    }, [account]);

    const callApprove = async () => {
        if (!isLoadingApprove) {
            setIsLoadingApprove(true);

            try {
                    const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
                    const result = await contractBusd.approve(
                      config.contractRouter,
                      MAX_VALUE
                    );

                callNotification({
                    type: "info",
                    message: "Transaction was sent. Please wait",
                    autoClose: 10000
                });

                setIsWaitingApprove(true);

                await result.wait();
            } catch (e) {
                setIsWaitingApprove(false);
                setIsLoadingApprove(false);
            }

            setIsWaitingApprove(false);
            setIsLoadingApprove(false);
            checkApprove();
        }
    };

  return (
      <Modal isOpened={openedModal} onClose={handleCloseModal}>
        <div className="relative flex flex-col items-center justify-center bg-[#141517] w-[600px] h-[454px] rounded-3xl pt-10 pb-12 sm:w-full sm:px-5">
            <span className="font-poppins text-3xl  text-white font-medium z-[1]">F-ID Registration</span>
            <img className="z-[1]" src="/images/registerModal/token.png" />
            {balanceBusd < 10 && <span className="bottom-[100px] text-red text-[14px] absolute">Not enough BUSD to registration</span>}
            <button onClick={() => {
                if (isNeedApprove) {
                    callApprove()
                } else {
                    onRegistration()
                }
            }} disabled={
                balanceBusd < 10 ||
              transactionInfo.isWaiting ||
              isLoadingApprove ||
              isWaitingApprove
            } className="flex items-center justify-center w-[286px] h-[40px] bg-white rounded-[50px] mt-[20px] z-[1] sm:w-full">
                <span className="text-black font-semibold"> {transactionInfo.isWaiting ||
                isLoadingApprove ||
                isWaitingApprove
                  ? "Loading..."
                  : isNeedApprove
                    ? "Approve registration"
                    : isRegistered ? 'You already registered' : "Registration (10 BUSD)"}</span>
            </button>
            <img className="absolute top-0 z-[0] sm:w-full sm:h-full" src="/images/registerModal/shadow.png" />
        </div>
      </Modal>
  );
};
