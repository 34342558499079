import React, { useState } from "react";

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

export const FaqItem = ({ title, desc }) => {

  const [isActive, setIsActive] = useState(false);

  return (
        <AccordionItem  className='border-[1px] border-white-100 rounded-3xl w-full backdrop-blur-xl sm:rounded-2xl '>
            <AccordionItemHeading className="w-full">
                <AccordionItemButton  className="flex items-center justify-between ">
                    <button onClick={() => setIsActive(!isActive)} className='flex items-center justify-between w-full space-x-2.5 p-6 sm:p-4'>
                        <h3 className='text-xl text-white sm:text-base text-left'>{title}</h3>
                        <img alt="" className={`w-[24px] h-[24px] sm:w-[20px] sm:h-[20px] transition-all ease-out ${isActive ? 'rotate-45' :'rotate-0'}`}  src='/icons/FaqList/plusIcon.svg' />
                    </button>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='px-6 pb-6 sm:pb-4 sm:px-4'>
                <span className='text-lg text-[#828282] font-light inter sm:text-[13px]'>
                    {desc}
                </span>
            </AccordionItemPanel>
        </AccordionItem>
  )
}