import { callNotification } from "./notification";
import { parseErrorToUserReadableMessage } from "./format";
import { CONTRACT_NAMES } from "./constants";

export const userAddressToId = async (userAddress, contract) => {
  let response = await contract.users(userAddress);
  return response && response.id ? response.id : null;
};

export const userIdToAddress = async (user, contract) => {
  return await contract.userIds(user);
};

export const userExists = async (user, contract) => {
  let exists;
  user = user.toString();
  if (user.match(/^[0-9]+$/)) {
    exists = await contract.isUserExists(await userIdToAddress(user, contract));
  } else if (user.match(/^0x[a-f0-9]{40}$/i)) {
    exists = await contract.isUserExists(user);
  } else {
    exists = false;
  }
  return exists;
};

export const checkRegistered = async ({
  account,
  getContract,
  contractType = CONTRACT_NAMES.BASE
}) => {
  try {
    const contract = await getContract(contractType);

    if (!(await userExists(account, contract))) {
      return Promise.resolve();
    } else {
      const id = await userAddressToId(account, contract);

      return Promise.reject({
        text: "this account is registered",
        id: parseInt(id)
      });
    }
  } catch (e) {
    callNotification({
      type: "error",
      message: parseErrorToUserReadableMessage(e)
    });
    return Promise.reject(e);
  }
};

export const checkUserCommision = isRegistred => {
  if (isRegistred) {
    return 1;
  } else {
    return 1;
  }
};
