import React, { useState, useRef } from 'react';
import { motion, useScroll, useTransform } from "framer-motion";
import Spline from '@splinetool/react-spline';

export const Advantages = () => { 

    const advantagesList = [
        {
            title: '100% of tokens circulate on the free market',
            desc: <>
                <span>Smart contract ownership is renounced, code is open for audit.</span>
                <span>No tokens belong to creators or advisors from the very launch.</span>
                <span>No fees and limits.</span>
            </>,
            animationUrl: 'https://prod.spline.design/QY8nXFra8yMN2j4V/scene.splinecode',
            img: '1',
        },
        {
            title: 'FRGX has real utility value on the market',
            desc: <>
                <span>FRGX has real value in a number of successful DeFi projects, including games, affiliate marketing, crypto staking, and so on.</span>
                <span>Even more projects are on the way.</span>
            </>,
            animationUrl: 'https://prod.spline.design/PBnnA09kNqf01EoI/scene.splinecode',
            img: '2',
        },
        {
            title: 'Verified and listed by leading platforms',
            desc: <>
                <span>FRGX is revised and listed by top websites, such as CoinMarketCap, CoinGecko, and so on.</span>
                <span>FRGX is listed and marked as trustworthy by decentralized exchanges.</span>
                <span>CEX listing coming soon</span>
            </>,
            animationUrl: 'https://prod.spline.design/amxdp3YsNOhgO0Qz/scene.splinecode',
            img: '3',
        },
        {
            title: 'Audited by professionals',
            desc: <>
                <span>Prior to its launch, FRGX smart contract and tokenomics were audited by industry professionals. </span>
                <span>This ensured a fail-proof launch and remarkable performance from the very beginning and in the long run.</span>
            </>,
            animationUrl: 'https://prod.spline.design/U2lS3iieF9CHmpjX/scene.splinecode',
            img: '4',
        },
        {
            title: 'Elaborate roadmap',
            desc: <span>The token roadmap has been designed so that all events contribute to increasing interest from the community and strengthening the token's position.</span>,
            animationUrl: 'https://prod.spline.design/QRvC2lyqHXjIScSJ/scene.splinecode',
            img: '5',
        },
        {
            title: 'Growth potential',
            desc: <span>From the moment of launch, FRGX demonstrated an outstanding dynamics and gained a massive support from the crypto community. As for now, FRGX keeps conquering the market.</span>,
            animationUrl: 'https://prod.spline.design/gh1up8tGJm-qoxMY/scene.splinecode',
            img: '6',
        },
    ]

    return (
        <div className="w-full max-w-[1500px] px-[108px] sm:px-0 sm:mt-[60px]">
            <div className="relative flex flex-col items-start space-y-[60px] relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] sm:items-center sm:space-y-10 ">
                <h2 className="text-[50px] poppins text-white font-semibold uppercase sm:text-[30px] sm:text-center">Advantages<br className="hidden sm:flex"/> of the FRGX Token</h2>
                <div className="grid grid-cols-3 grid-rows-2 gap-6 sm:flex-col overflow-auto sm:grid-cols-2 sm:grid-rows-3 sm:gap-3">
                    {advantagesList?.map((item, itemIndex) => {
                        return (
                            <div className="advantages_border p-[1px] rounded-[30px]" key={itemIndex}>
                                <div className="flex flex-col items-center justify-start bg-[#0C0C0C] rounded-[30px] w-[392px] h-[596px] px-5 sm:w-full sm:h-full">
                                    {/* <div className="w-[300px] sm:w-[80px]">
                                        <Spline className="w-full scale-50 flex items-center justify-center" scene={item?.animationUrl} />
                                    </div> */}
                                    <img className="w-[300px] " src={`/images/advantages/${item?.img}.png`}/>
                                    <div className="flex flex-col space-y-5">
                                        <span className="text-2xl text-white inter font-medium sm:text-xs">{item?.title}</span>
                                        <div className="flex flex-col space-y-3 text-white-500 inter font-light leading-[22px] sm:leading-[16px] sm:text-[10px]">
                                            {item?.desc}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}


