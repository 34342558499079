import React, { useCallback, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "../../helpers/hooks/useOnClickOutside";

const Select = ({ className, value, onChange, data }) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef();

  const onOptionClicked = useCallback(
    v => event => {
      onChange(v);
      setOpened(false);
    },
    [onChange]
  );


  const toggling = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useOnClickOutside(ref, () => {
    setOpened(false);
  });

  const activeOption = useMemo(() => {
    return data?.find(item => item.title === String(value));
  }, [value, data]);

  return (
    <div className="relative" ref={ref}>
      <div
        className={`cursor-pointer flex bg-white-40 justify-start items-center space-x-2.5 rounded-2xl min-h-50px py-3 pl-4 pr-6 min-w-[115px]  sm:min-w-[77px] sm:py-[7px] sm:rounded-[9px] sm:pl-[4.6px]
              ${opened && "bg-[#2E3030] rounded-b-none border-b-white-200"} 
              ${className}
            `}
        onClick={toggling}
      >
        {!!activeOption?.icon ? (
          <img src={activeOption?.icon} className="w-5 h-5 sm:w-4 sm:h-4" />
        ) : null}
        <span className="text-white sm:text-xs">{activeOption?.title}</span>
        <img
          src="/icons/arrow_down.svg"
          className={`absolute w-6 h-6 right-1 top-1/2 -translate-y-1/2 transition duration-300 ease-in-out sm:w-[14px] sm:h-[14px] ${opened &&
            "rotate-180"} `}
        />
      </div>
      {opened && (
        <div className="absolute top-full z-[20] max-h-[250px] w-full bg-white-40 rounded-2xl rounded-t-none overflow-auto drop-shadow-xl backdrop-blur-3xl">
          <ul className="w-full bg-white-40 drop-shadow-md rounded-2xl rounded-t-none text-white outline-none backdrop-blur-3xl">
            {data?.map((option, index) => {
              return (
                <li
                  className="min-h-50px py-3 pl-4 pr-6 border-b border-white-300 last:border-none hover:cursor-pointer hover:bg-gray-500 sm:pl-[4.6px] sm:py-[7px] "
                  onClick={onOptionClicked(option?.title)}
                  key={index}
                >
                  <div className="flex items-center justify-start space-x-2.5">
                    {!!option?.icon && (
                      <img src={option?.icon} className="w-5 h-5 sm:w-[14px] sm:h-[14px]" />
                    )}
                    <span className="sm:text-xs">{option?.title}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export { Select };
