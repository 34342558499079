import React from "react";
import Marquee from 'react-fast-marquee';

export const Partners = () => {
  const firstPartnersList = [
    {
      img: "coinMarketCap.svg",
      link: "https://coinmarketcap.com/"
    },
    {
      img: "coinGecko.svg",
      link: "https://www.coingecko.com/"
    },
    {
      img: "pancakeSwap.svg",
      link: "https://pancakeswap.finance/"
    },
    {
      img: "mexc.svg",
      link: "https://www.mexc.com/"
    },
    {
      img: "cointelegraph.svg",
      link: "https://cointelegraph.com/"
    },
  ];

  const secondPartnersList = [
    {
      img: "coindizzy.svg",
      link: "https://coindizzy.com/"
    },
    {
      img: "liveCoin.svg",
      link: "https://www.livecoinwatch.com/"
    },
    {
      img: "bitsDay.svg",
      link: "https://bitsday.com/"
    },
    {
      img: "coinMooner.svg",
      link: "https://coinmooner.com/"
    },
    {
      img: "qornex.svg",
      link: "https://qornex.com/"
    },
  ];
  const thirdPartnersList = [
    {
      img: "coinMarketCap.svg",
      link: "https://coinmarketcap.com/"
    },
    {
      img: "coinGecko.svg",
      link: "https://www.coingecko.com/"
    },
    {
      img: "pancakeSwap.svg",
      link: "https://pancakeswap.finance/"
    },
    {
      img: "mexc.svg",
      link: "https://www.mexc.com/"
    },
    {
      img: "cointelegraph.svg",
      link: "https://cointelegraph.com/"
    },
    {
      img: "metattalks.svg",
      link: ""
    },
  ];

  return (
    <div className="flex items-center  flex flex-col sm:px-5 sm:pt-[50px] z-[1] sm:space-y-3">
        <div className="flex space-x-[72px] sm:space-x-[40px] ">
              {firstPartnersList?.map((item, itemIndex) => {
                  return (
                    <a
                      target="_blank"
                      key={itemIndex}
                      href={item?.link}
                      className="flex items-center justify-center rounded-[54px] w-full h-[70px] hover:bg-white-30 cursor-pointer grayscale sm:grayscale-0 hover:grayscale-0 sm:hover:bg-transparent sm:w-[158px]  " rel="noreferrer"
                    >
                      <img
                        alt="partnersImage"
                        className="sm:w-full mx-auto sm:overflow-hidden"
                        src={`/icons/partners/${item?.img}`}
                      />
                    </a>
                  );
                })}
          </div>
          <div className="flex mr-[150px] space-x-[72px] sm:space-x-[40px]">
              {secondPartnersList?.map((item, itemIndex) => {
                  return (
                    <a
                      target="_blank"
                      key={itemIndex}
                      href={item?.link}
                      className="flex items-center justify-center rounded-[54px] w-full h-[70px] hover:bg-white-30 cursor-pointer grayscale sm:grayscale-0 hover:grayscale-0 sm:hover:bg-transparent sm:w-[158px]  " rel="noreferrer"
                    >
                      <img
                        alt="partnersImage"
                        className="sm:w-full mx-auto sm:overflow-hidden"
                        src={`/icons/partners/${item?.img}`}
                      />
                    </a>
                  );
                })}
          </div>
          <div className="flex space-x-[72px] sm:space-x-[40px]">
              {thirdPartnersList?.map((item, itemIndex) => {
                  return (
                    <a
                      target="_blank"
                      key={itemIndex}
                      href={item?.link}
                      className="flex items-center justify-center rounded-[54px] w-full h-[70px] hover:bg-white-30 cursor-pointer grayscale sm:grayscale-0 hover:grayscale-0 sm:hover:bg-transparent sm:w-[158px]  " rel="noreferrer"
                    >
                      <img
                        alt="partnersImage"
                        className="sm:w-full mx-auto sm:overflow-hidden"
                        src={`/icons/partners/${item?.img}`}
                      />
                    </a>
                  );
                })}
          </div>
    </div>
  );
};
