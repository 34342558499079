import React from "react";

export const Item = ({name, nickname, title, desc, avatar, pictures}) => {
    return (
        <div className="flex flex-col space-y-6 max-w-full sm:min-w-[311px]">
            <div className="flex space-x-3 ">
                <img className="w-[48px] h-[48px]" src={`/images/blog/avatars/${avatar}.png`}/>
                <div className="flex flex-col space-y-[2px]">
                    <span className="text-x text-white inter sm:text-sm">{name}</span>
                    <span className="text-sm text-white-500 font-light">{nickname}</span>
                </div>
            </div>
            <div className="flex flex-col space-y-4">
                <span className="text-2xl text-white inter font-medium sm:text-lg max-w-[390px]">{title}</span>
                <img className="max-h-[240px] rounded-[16.8px] sm:w-[400px] sm:rounded-3xl" src={`/images/blog/pictures/${pictures}.png`}/>
                <span className="text-[19px] text-white-500 inter font-light sm:text-sm">{desc}</span>
            </div>
         </div>
    )
}