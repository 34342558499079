import React from "react";
import { Modal } from "../../Modal";
import { Hint } from "../../Hint";

export const CommisionModal = ({ openedModal, handleCloseModal, isRegistered = false }) => {
  return (
      <Modal isOpened={openedModal} onClose={handleCloseModal}>
        {isRegistered ? 
        <div className="flex flex-col items-center justify-between bg-[#141517] w-[600px] h-[527px] rounded-3xl sm:rounded-none sm:rounded-t-3xl pt-[19px] pb-10 sm:w-full sm:px-5">
            <img className="z-[1]" src="/images/registerModal/token.png" /> 
            <img className="absolute top-0 z-[0] sm:w-full sm:h-full rounded-3xl" src="/images/commisionModal/low.png" />
            <span className="font-poppins text-2xl text-[#46E064] font-semibold z-[1]">Your commission is low!</span>
            <div className="flex flex-col space-y-4 items-center">
                <span className="text-lg text-[#CCCCCC] inter font-light">Your referral link</span>
                <Hint type={1} />
            </div>
        </div>
        :
        <div className="flex flex-col items-center justify-between bg-[#141517] w-[600px] h-[527px] rounded-3xl sm:rounded-none sm:rounded-t-3xl pt-[19px] pb-10 sm:w-full sm:px-5">
            <img className="z-[1]" src="/images/registerModal/token.png" /> 
            <img className="absolute top-0 z-[0] sm:w-full sm:h-full rounded-3xl" src="/images/commisionModal/high.png" />
            <span className="font-poppins text-2xl text-[#FFBB54] font-semibold z-[1]">Your commission is too high!</span>
            <div className="flex flex-col space-y-4 items-center">
                <span className="text-lg text-white inter font-light text-center">To have a reduced commission,<br/> please register</span>
                <Hint type={2} />
            </div>
        </div>
        }
      </Modal>
  );
};
