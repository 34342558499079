import React, { useEffect, useState, useMemo } from "react";
import { CONTRACT_NAMES } from "../../helpers/constants";
import { userExists } from "../../helpers/checks";
import { useGetContract } from "../../helpers/hooks/useGetContract";
import { useWeb3React } from "@web3-react/core";
import { RegistrationModal } from "../Modals/RegistrationModal";
import { useModal } from "../../helpers/hooks/useModal";
import { copy } from "../../helpers/text";

export const Hint = ({ type = 1, contractsFetch }) => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();

  const { openedModal, onOpen, onClose } = useModal();

  const [isRegistered, setIsRegistered] = useState(false);
  const [userId, setUserId] = useState(null);

  const checkIsRegistered = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.BASE);
      const total = await userExists(account, contract);
      const exists = await contract.users(account);

      setUserId(parseInt(exists));

      setIsRegistered(total);
    } catch (e) {
      setIsRegistered(false);
    }
  };

  useEffect(() => {
    checkIsRegistered();
  }, [account]);

  const firstHint = useMemo(() => {
    const reflink = `frgx.finance?fid=${userId}`;
    if (!isRegistered) {
      return null;
    } else {
      return (
        <div className="py-2">
          <button
            onClick={() => copy(reflink)}
            className="notranslate z-[10] cursor-pointer flex items-center justify-center px-4 h-[40px] sm:h-[35px] rounded-3xl border-[1px] border-white space-x-4   "
          >
            <span className="text-white leading-none sm:whitespace-nowrap text-base sm:text-sm ">{reflink}</span>
            <img className="w-6 h-6" src="/icons/copyIcon.svg" />
          </button>
        </div>
      );
    }
  }, [isRegistered, userId]);

  const secondHint = useMemo(() => {
    if (isRegistered) {
      return null;
    } else {
      return (
        <div className="py-2">
          <button
            onClick={onOpen}
            className="z-[10] flex items-center justify-center w-[152px] h-[40px] sm:h-[35px] rounded-3xl bg-white"
          >
            <span className="text-black font-semibold text-base">Get F_ID</span>
          </button>
          <RegistrationModal
            isRegistered={isRegistered}
            handleCloseModal={onClose}
            openedModal={openedModal}
            contractsFetch={contractsFetch}
          />
        </div>
      );
    }
  }, [isRegistered, openedModal]);

  switch (type) {
    case 1:
      return firstHint;
    case 2:
      return secondHint;
    default:
      return null;
  }

  return;
};
