import React from "react";
import { Item } from "./item";

export const FeedBack = () => {
    return (
        <div className="w-full max-w-[1500px] px-[108px] sm:px-0">
            <div className="relative flex flex-col items-start space-y-[60px] relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] mt-[216px] sm:mt-[80px] sm:space-y-10 sm:items-center ">
                <h2 className="text-[50px] poppins text-white font-semibold uppercase sm:text-[30px] sm:text-center">BLOG</h2>
                <div className="w-full">
                        <div className="grid grid-cols-3 grid-rows-2 gap-y-[60px] gap-x-5 sm:grid-rows-1 sm:grid-cols-6 sm:gap-[330px] sm:overflow-auto sm:w-full">
                           <Item avatar="zebra" pictures="zebra" name="Zebra" nickname="@Zebra1337" title="FRGX Token: Paving the Way for Innovation in Decentralized Fina..." desc="The FRGX Finance token, designed with DeFi projects utility token in mind, has g..."/>
                           <Item avatar="hell" pictures="hell" name="Hell Direct" nickname="@HellDirect" title="Unveiling the Potential: FRGX Token and its Ecosystem" desc="This comprehensive FRGX token project overview reveals its future prospects, d..."/>
                           <Item avatar="den" pictures="den" name="Den Andersen" nickname="@AndersenDD" title="Explore FRGX Token: The Ultimate DeFi Opportunity for 2..." desc="The FRGX Token, a decentralized finance (DeFi) utility token, has gained significa..."/>
                           <Item avatar="jacob" pictures="jacob" name="Jacob" nickname="@youradmine" title="Community Empowerment at Heart: FRGX Token Gains Mome..." desc="FRGX token, launched by FRGX Finance, has garnered attention for its unique ap..."/>
                           <Item avatar="ksenia" pictures="ksenia" name="Кsenia MD" nickname="@xeniacreator" title="FRGX Takes Flight: Transitioning to BASE Network for Enhanced..." desc="On June 7th, FRGX.finance will make a significant leap forward by launching a..."/>
                           <Item avatar="alex" pictures="alex" name="Alex" nickname="@AleX537" title="FRGX Launchpad: Revolutionizing Meme Coin Launches with... " desc="The meme coin market has exploded in recent years, with new projects poppin..."/>
                        </div>
                    </div>
                </div>
                
        </div>
    )
}