import React from "react";

export const Item = ({ava, name, desc}) => {
    return (
        <div className="flex flex-col space-y-3 px-5 py-6 bg-white-40 rounded-[30px] border-[1px] border-white-100 sm:px-3 sm:py-4 sm:rounded-[19.6px]">
            <div className="flex space-x-4 items-center">
                <img className="sm:h-6 sm:w-6" src={`/images/feedBack/userPhoto/${ava}.png`}/>
                <span className="text-x text-white inter font-medium sm:text-xs">{name}</span>
            </div>
            <span className="max-w-[352px] inter text-white-500 font-light sm:text-[10px]">
                {desc}
            </span>

        </div>
    )
}