import React from "react";
import GoogleTranslate from "../../components/GoogleTranslate";

export const KmkPage = () => {

  return (
    <div className="h-full bg-[#080909] relative flex flex-col items-center overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="flex flex-col items-center justify-center h-full bg-[#080909] text-white w-full pt-7 sm:max-w-full sm:pt-6">
          <div className="w-full max-w-[1000px] px-[108px] pb-10 space-y-5 sm:px-5">
            <img className="mx-auto max-h-[400px] rounded-[20px]" src="/images/kmkPage/main.jpg" alt="" />
            <p>
              We confirm our verification request and are eager to meet your requirements to validate our project's legitimacy. As a dedicated team valuing transparency, we recognize the significance of offering verified information to the community. In line with your request, we have shared a verification post on our official social media accounts. Find the post at:
            </p>
            <div className="flex flex-col space-y-2.5">
              <a className="underline" target="_blank" href="https://twitter.com/FRGXfinance/status/1764648920003518853">Twitter Verification CoinMarketCap</a>
              <a className="underline" target="_blank" href="https://t.me/frgxfinance/286">Telegram Verification CoinMarketCap</a>
            </div>
            <p>To further authenticate our application, we would like to provide the necessary details as requested:</p>
            <div className="flex flex-col space-y-2.5">
              <span>(i) Add cryptoasset [360000493112]</span>
              <a className="underline" target="_blank" href="https://coinmarketcap.com/dexscan/bsc/0x55f303a94b8b085917459ae7e0b252b0e20b5add/">(ii) CMC Dexscan https://coinmarketcap.com/dexscan/bsc/0x55f303a94b8b085917459ae7e0b252b0e20b5add/</a>
            </div>
            <p>
              We would also like to guarantee the legitimacy of our request by providing the official Telegram account <a className="underline" target="_blank" href="https://t.me/frgxfinance">@frgxfinance</a> and the Telegram of our team member who can confirm our request: <a className="underline" target="_blank" href="https://t.me/youradmine">@youradmine</a>. 
            </p>
            <p>
              We kindly ask the CoinMarketCap team to review our verification post and project details for FRGX Finance. We are confident in our project's potential impact in the cryptocurrency space and pledge to uphold integrity standards. 
              <br/> <br/>We appreciate your attention and anticipate future collaboration with CoinMarketCap.
            </p>
            <div className="flex flex-col space-y-2.5">
              <a className="underline" target="_blank" href="https://frgx.finance/">Website https://frgx.finance/</a>
              <a className="underline" target="_blank" href="https://twitter.com/FRGXfinance">X (Twitter) https://twitter.com/FRGXfinance</a>
              <a className="underline" target="_blank" href="https://t.me/frgxfinance">Telegram Channel https://t.me/frgxfinance</a>
            </div>
          </div>
        </div>
      </div>
      <GoogleTranslate />
    </div>
  );
}