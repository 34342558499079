import React, { useState, useRef, useEffect, useMemo } from "react";
import { Header } from "../../components";
// import { Statics } from "../../features/main/Statistics";
import { Partners } from "../../features/main/Partners";
import { Tokenomics } from "../../features/main/Tokenomics";
import { TokenInfo } from "../../features/main/TokenInfo";
import { FaqList } from "../../features/main/FaqList";
import { Footer } from "../../components/footer";
import { Advantages } from "../../features/main/Advantages";
import { isBefore } from "date-fns";
import { FIRST_THIRTY_DATE, SWAP_TOKEN_START } from "../../helpers/constants";
import { ThirtySwap } from "../../features/main/SwapToken/ThirtySwap";
import { useWeb3React } from "@web3-react/core";
import { useGetContract } from "../../helpers/hooks/useGetContract";
import { CONTRACT_NAMES } from "../../helpers/constants";
import { userExists } from "../../helpers/checks";
import { Timer } from "../../components";
import { MainSwap } from "../../features/main/SwapToken/MainSwap";
import config from "../../helpers/config";
import { add }  from "date-fns";
import GoogleTranslate from "../../components/GoogleTranslate";
import { RoadMap } from "../../features/main/RoadMap";
import { FrgxUtility } from "../../features/main/FrgxUtility";
import { Reviews } from "../../features/main/Reviews";
import { FeedBack } from "../../features/main/FeedBack";
import { MemecoinBanner } from "../../features/main/MemecoinBanner";

export const Main = () => {
  const { account, chainId } = useWeb3React();
  const isAllowedChainId = Number(chainId) === Number(config.allowedChainId);
  const [isCompletedThirtyCountdown, setIsCompletedThirtyCountdown] = useState(
    !isBefore(new Date(), new Date(FIRST_THIRTY_DATE))
  );

  const [isCompletedCountdown, setIsCompletedCountdown] = useState(
    !isBefore(new Date(), new Date(SWAP_TOKEN_START))
  );

  const tm = useRef(null);
  const isFetching = useRef(false);
  const [contracts, setContracts] = useState({
    liquidityBNB: "0x55f303a94b8b085917459ae7e0b252b0e20b5add",
    liquidityBUSD: "0x3b672190bc166a72bf146994151513773af1db86",
    frgxBSC: "0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c",
    frgxTRC: "TR8VCZfjRnsqGmuga8uTyDqJSPEA55JdG1",
    multiplier: 3
  });

  const swapTokensRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const socialRef = useRef(null);
  const roadMapRef = useRef(null);
  

  const callFetch = async () => {
    let param = 0;

    if (!isBefore(new Date(), add(new Date(SWAP_TOKEN_START), {seconds: -10}))) {
      param = Date.now();
    }

    fetch(
      `https://forsage-storage.s3.eu-central-1.amazonaws.com/frgx/config.json${
        param ? `?time=${param}` : ""
      }`
    )
      .then(result => {
        return result.json();
      })
      .then(res => {
        if (res.liquidityBNB) {
          tm.current && clearTimeout(tm.current);
          setContracts({
            liquidityBNB: res.liquidityBNB,
            liquidityBUSD: res.liquidityBUSD,
            frgx: res.frgx,
            multiplier: res.multiplier
          });
        } else {
          tm.current = setTimeout(callFetch, 1000);
        }
      })
      .catch(e => {
        tm.current && clearTimeout(tm.current);
        tm.current = setTimeout(callFetch, 1000);
      });
  };

  // useEffect(() => {
  //   if (!isFetching.current) {
  //     isFetching.current = true;
  //     callFetch();
  //   }
  // }, []);

  const [isRegistered, setIsRegistered] = useState(false);
  const { getContract } = useGetContract();

  const checkIsRegistered = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.BASE);
      const total = await userExists(account, contract);

      setIsRegistered(total);
    } catch (e) {
      setIsRegistered(false);
    }
  };

  useEffect(() => {
    if (isAllowedChainId) {
      checkIsRegistered();
    }
  }, [account, isAllowedChainId]);

  const renderSwap = useMemo(() => {
    if (!isCompletedThirtyCountdown && isCompletedCountdown) {
      return <ThirtySwap
      isRegistered={isRegistered}
      contractsFetch={contracts}
      customRef={swapTokensRef}
    />
    } 
      return <MainSwap
              isRegistered={isRegistered}
              contractsFetch={contracts}
              customRef={swapTokensRef}
            />

  }, [isCompletedThirtyCountdown, isCompletedCountdown, isRegistered, contracts, swapTokensRef])

  return (
    <div className="h-full bg-[#070707] relative flex flex-col items-center overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="flex flex-col items-center justify-center h-full bg-[#070707] w-full pt-7 sm:max-w-full sm:pt-6">
          <Header
            isRegistered={isRegistered}
            swapTokensRef={swapTokensRef}
            tokenomicsRef={tokenomicsRef}
            socialRef={socialRef}
          />
          <TokenInfo swapTokensRef={swapTokensRef} roadMapRef={roadMapRef} />
          <Partners />
          {renderSwap}
          <Advantages />
          <FrgxUtility />
          <Tokenomics />
          <RoadMap />
          <MemecoinBanner />
          <Reviews />
          <FeedBack />
          <FaqList />
          {!isCompletedThirtyCountdown && (
            <div className="hidden">
              <Timer
                customDate={FIRST_THIRTY_DATE}
                isCompletedCountdown={isCompletedThirtyCountdown}
                setIsCompletedCountdown={setIsCompletedThirtyCountdown}
              />
            </div>
          )}
          {!isCompletedCountdown && (
            <div className="hidden">
              <Timer
                customDate={SWAP_TOKEN_START}
                isCompletedCountdown={isCompletedCountdown}
                setIsCompletedCountdown={setIsCompletedCountdown}
              />
            </div>
          )}
        </div>
        <Footer contractsFetch={contracts} />
      </div>
      <GoogleTranslate />
    </div>
  );
};
