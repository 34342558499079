import React from "react";
import {
    Accordion,
} from 'react-accessible-accordion';
import { FaqItem } from './item';

import 'react-accessible-accordion/dist/fancy-example.css';

export const FaqList = () => { 
    const info = [
    {
        title: 'What is FRGX Token?',
        desc: <span>FRGX Token is a free market token on the BNB Chain blockchain with an open smart contract code and a limited emission.</span>
    },
    {
        title: 'Who created the FRGX token?',
        desc: <span>The FRGX team holds a proven track record of 4 years in developing the largest smart contract platform for online business, GameFi, NFT, and other tools, supporting 3 million+ participants. This experience allows them to create and maintain products at the highest level. That's why we can guarantee that this digital economy product will be developed and launched through the most advanced and unmatched technologies</span>
    },
    {
        title: 'What are the token price forecasts for FRGX?',
        desc: <span>The token has all prospects for stable price growth. However, it is impossible to give exact terms and figures, as the token operates on the free market. However, we can be sure that the token will reach the price of $0.01 on the launch day.</span>
    },
    {
        title: 'What is Fair Launch?',
        desc: <span>Fair launch can also be described as an honest and equitable launch. This means that each participant will interact with the token in a fully decentralized environment, and the entire token issue will be available to everyone right from the moment of launch. It also means that 100% of the tokens will be released to the free market on the BNB Chain blockchain, and the token developers will not have any advantages.</span>
    },
    {
        title: 'Why is FRGX launched in BNB Chain, and what makes BSC tokens successful among holders?',
        desc: <span>BNB Chain is a trusted blockchain on which many tokens and projects successfully operate. Moreover, it is the blockchain where participants with F-IDs work, so they won't have to make much effort to master the FRGX token.</span>
    },
    // {
    //     title: 'How will equal rights for all participants and long-term demand for the token be ensured?',
    //     desc: <span>To provide equal rights to all holders, the following measures will be introduced: <br />
    //     1) The first purchase from each wallet is limited to $50, there is no limit from the second purchase. The restriction applies for 1 day.<br />
    //     2) All purchases are limited to $100. The restriction applies for 1 day.<br />
    //     3) GAS Price limit for everyone.<br />
    //     4) Blacklist for dishonest participants, which is filled both in advance and online.<br />
    //     </span>
    // },
    {
        title: 'Should I invest in FRGX?',
        desc: <span>We recommend evaluating your situation yourself and weighing the pros and cons before making any cryptocurrency investments. We also recommend diversifying your risks and investing only with your personal spare funds. None of the information on this website or in other materials is an investment recommendation.</span>
    },
    {
        title: 'How to buy FRGX tokens?',
        desc: <span>You can buy them on the official FRGX website, on DEX-exchanges, and in the nearest future we expect the token to be listed on CEX-exchanges.</span>
    },
]
    return (
        <div className="w-full max-w-[1500px] px-[108px] sm:px-4">
            <div className="relative flex items-center justify-between pt-[120px] sm:flex-col sm:space-y-10">
                <div className="flex flex-col uppercase text-[54px] font-semibold leading-[80px] sm:leading-[36px] sm:text-3xl sm:items-center">
                    <span className="text-white">Frequently</span>
                    <div className="flex flex-col sm:flex-row space-x-1">
                        <span className="faq_title">Asked</span>
                        <span className="faq_title">Questions</span>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center text-white space-y-5 w-full max-w-[700px] sm:max-w-full">
                    <Accordion allowZeroExpanded allowMultipleExpanded className="w-full space-y-5 ">
                        {info.map((item, itemIndex) => {
                            return <FaqItem {...item} key={itemIndex} />
                        })} 
                    </Accordion>
                </div> 
            </div>
        </div>
    )
}


