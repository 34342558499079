import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { DEFAULT_GAS_LIMIT, CONTRACT_NAMES } from "../constants";
import { increaseByPercent } from "../numbers";

export const useRegistration = () => {
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const { getContract } = useGetContract();

  const registration = async (uplineId = 1) => {
    if (!isLoadingRegistration) {
      setIsLoadingRegistration(true);
      try {
        const contract = await getContract(CONTRACT_NAMES.ROUTER);

        const xbaseContract = await getContract(CONTRACT_NAMES.BASE);
        const address = await xbaseContract.userIds(uplineId);

        let gas = null;
        try {
          gas = await contract.estimateGas.forsageRegistrationBUSD(address);
        } catch (e) {
          //
        }

        return await contract.forsageRegistrationBUSD(address, {
          gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT
        });
      } catch (e) {
        return Promise.reject(e);
      } finally {
        setIsLoadingRegistration(false);
      }
    }
  };

  return {
    registration,
    isLoadingRegistration
  };
};
