import React, { useState } from "react";

export const FrgxUtility = () => {

    const featuresList = [
        {
          img: 'community',
          title: 'Community',
          content: [
            {
              title: 'Community',
              desc: 'Right from the start FRGX positioned itself as a SocialFi token and united 40,000+ holders from around the world who are incredibly supportive and positive about the project.',
              picture: 'community',
            }
          ],
        },
        {
          img: 'frgxSpace',
          title: 'FRGX Space',
          content: [
            {
              title: 'Road Map',
              desc: <span>FRGX Space is a SocialFi ecosystem within the DeFi landscape equipped with utilities powered by the FRGX token, offering personal spaces for new and existing products. </span>,
              picture: 'frgxSpace',
            }
          ],
        },
        {
          img: 'fees',
          title: <span>No fees,<br /> No owners</span>,
          content: [
            {
              title: 'No fees, No owners',
              desc: 'Using FRGX does not involve any additional expenses, besides from the minimal BNB Chain fee. Smart contract ownership has been renounced, and FRGX is fully autonomous. No interference is possible both from the creators and/or third parties.',
              picture: 'fees',
            }
          ],
        },
        {
          img: 'litepaper',
          title: <span>Litepaper and <br /> Project Audit</span>,
          content: [
            {
              title: 'Litepaper and Project Audit',
              desc: 'For all the details on the tokenomics and utility value of FRGX, explore the project Lite Paper and Smart Contract Audit.',
              picture: 'litepaper',
            }
          ],
        },
        {
          img: 'burning',
          title: <span>Burning <br/> in products</span>,
          content: [
            {
              title: 'Burning in products',
              desc: 'Many projects that run on FRGX token have a built-in mechanism of token burning. This ensures a slow but steady emission reduction and boosts the token value.',
              picture: 'burning',
            }
          ],
        },
        {
          img: 'memecoin',
          title: 'Memecoin Launchpad',
          content: [
            {
              title: 'Road Map',
              desc: 'FRGX Launchpad provides a dedicated platform for projects to launch their memecoins with ease. Exclusive access to presales and whitelists is reserved for holders of the FRGX Base token, offering a unique advantage to our community.',
              picture: 'memecoin',
            }
          ],
        },
        {
          img: 'qornex',
          title: 'Qornex',
          content: [
            {
              title: 'Qornex',
              desc: 'Qornex is a decentralized marketing platform that operates on FRGX token. Users can earn FRGX through staking, farming, and inviting new users.',
              picture: 'qornex',
            }
          ],
        },

      ]    

      const [currentTab, setCurrentTab] = useState(0);

    return (
        <div className="w-full max-w-[1500px] px-[108px] sm:px-0">
            <div className="relative flex flex-col items-start space-y-[60px] relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] mt-[216px] sm:mt-[80px] sm:items-center sm:space-y-10 ">
                <h2 className="text-[50px] poppins text-white font-semibold uppercase sm:text-[30px] sm:text-center">Utility<br className="hidden sm:flex"/> in DeFi Projects</h2>
                    <div className="flex space-x-4 w-full h-[600px] rounded-[30px] sm:flex-col sm:space-x-0 sm:h-full sm:space-y-3 ">
                      <div className="advantages_border p-[1px] rounded-[30px] h-full sm:p-0 sm:bg-transparent sm:rounded-xl">
                        <div className="flex bg-[#0C0C0C] p-3 rounded-[30px] h-full sm:rounded-xl">
                                <div className="flex flex-col items-center justify-between w-[260px] sm:w-full sm:flex-row sm:overflow-auto sm:space-x-3">
                                    {featuresList?.map((item, itemIndex) => {
                                        const isActive = itemIndex === currentTab;
                                        return (
                                            <div className="flex w-full ">
                                                <button onClick={() => {setCurrentTab(itemIndex)}} className={`flex items-center rounded-2xl h-[72px] space-x-5 pl-10 sm:px-5 sm:py-0 w-full sm:items-center sm:justify-center  sm:rounded-xl sm:min-w-[167px] sm:h-[56px] sm:space-x-[10px] sm:border-[1px] sm:border-white-100  ${isActive ? 'bg-white-100 sm:border-none' : 'opacity-20'}`} key={itemIndex}>
                                                    <img className="sm:w-6 sm:h-6" src={`/icons/frgxUtility/${item?.img}.svg`}/>
                                                    <span className={`inter font-light sm:text-sm text-left text-white`}>{item?.title}</span>
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {featuresList[currentTab].content.map((item, itemIndex) => {
                          return (
                                  <div className=" p-[1px] rounded-[30px] h-full w-full sm:rounded-3xl">
                                    <div className="flex items-center justify-between w-full bg-[#0C0C0C] h-full justify-beеtwen rounded-[30px] space-x-10 pl-10 pr-3 py-3 sm:flex-col sm:space-x-0 sm:space-y-8 sm:border sm:border-[1px] sm:border-white-50 sm:py-6 sm:px-4 sm:rounded-3xl">
                                        <div className="flex flex-col space-y-3 inter " key={itemIndex}>
                                          <span className="text-white text-2xl sm:text-lg sm:w-full">
                                              {item?.title}
                                          </span>
                                          <span className="text-xl font-light text-white opacity-60 sm:text-sm sm:w-full">
                                              {item?.desc}
                                          </span>
                                        </div>
                                        <div className="sm:w-full w-full h-full">
                                          <img className="sm:w-full w-full h-full rounded-r-3xl min-w-[470px] sm:rounded-2xl sm:min-w-full" src={`/images/frgxUtility/${item?.picture}.png`} />
                                        </div>
                                    </div>
                                  </div>
                                )
                        })}
                    </div>
                </div>  
        </div>
    )
}