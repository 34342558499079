import React, { useState, useEffect, useMemo, Suspense } from "react";
import BigNumber from "bignumber.js";
import { useDebouncedEffect } from "../../../helpers/hooks/useDebounce";
import {
  CONTRACT_NAMES,
  MAX_VALUE,
  DEFAULT_GAS_LIMIT
} from "../../../helpers/constants";
import { useGetContract } from "../../../helpers/hooks/useGetContract";
import { useWeb3React } from "@web3-react/core";
import config from "../../../helpers/config";
import { increaseByPercent, toWei } from "../../../helpers/numbers";
import { callNotification } from "../../../helpers/notification";
import { addMinutes, getUnixTime } from "date-fns";
import { toast } from "react-toastify";
import { parseErrorToUserReadableMessage } from "../../../helpers/format";
import { useBalance } from "../../../helpers/hooks/useBalance";
import { Select } from "../../../components";
// timer
import {
  SWAP_TOKEN_START,
  FIRST_THIRTY_DATE
} from "../../../helpers/constants";
import { isBefore } from "date-fns";
import { motion } from "framer-motion";
import { Contract } from "@ethersproject/contracts";
import { liquidityAbi } from "../../../helpers/contractsAbi/liquidityAbi";

import { ConnectWallet } from "./comp/ConnectWallet";
import { CustomTimer } from "./comp/CustomTimer";

import { transactionalNumberToNumber } from "./ThirtySwap";

import { useCallTransaction } from "../../../helpers/hooks/useCallTransaction";

import { BurnResultModal } from "../../../components/Modals/BurnResultModal";
import { useModal } from "../../../helpers/hooks/useModal";

export function realNumberToTransactionalNumber(
  amount,
  decimals = 18,
  fixed = undefined
) {
  return new BigNumber(amount, 10).multipliedBy(`1e${decimals}`).toFixed(fixed);
}

export const MainSwap = ({ customRef, contractsFetch, isRegistered }) => {
  const { account, provider, chainId } = useWeb3React();
  const { getContract } = useGetContract();
  const isAllowedChainId = Number(chainId) === Number(config.allowedChainId);
  // timer

  const [isCompletedCountdown, setIsCompletedCountdown] = useState(
    !isBefore(new Date(), new Date(SWAP_TOKEN_START))
  );

  const { openedModal, onOpen, onClose } = useModal();

  // timer end

  // timer for first 30 min

  const [isCompletedThirtyCountdown, setIsCompletedThirtyCountdown] = useState(
    !isBefore(new Date(), new Date(FIRST_THIRTY_DATE))
  );


  const { onCallTransaction, transactionInfo } = useCallTransaction();

  const [payInputValue, setPayInputValue] = useState(10);
  const [burnInputValue, setBurnInputValue] = useState(0);
  const [burnedFrgx, setBurnedFrgx] = useState(0);
  const [amount, setAmount] = useState(0);

  const [isLoadingTransactionSwap, setIsLoadingTransactionSwap] = useState(
    false
  );
  const [isWaitingTransactionSwap, setIsWaitingTransactionSwap] = useState(
    false
  );
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isWaitingApprove, setIsWaitingApprove] = useState(false);
  const [isLoadingCheckApprove, setIsLoadingCheckApprove] = useState(false);
  const [isNeedApprove, setIsNeedApprove] = useState(false);
  const [chosenTokens, setChosenTokens] = useState(["BNB", "FRGX"]);

  const {
    balanceBnb = 0,
    balanceBusd = 0,
    balanceFrgx = 0,
    fetchBalance
  } = useBalance({ contractsFetch, withoutFixed: true });

  const balanceMapper = {
    BUSD: balanceBusd,
    BNB: balanceBnb,
    FRGX: balanceFrgx
  };

  const onChangePayInputValue = e => {
    const value = Number(+e.target.value);
    if (!isNaN(value)) {
      setPayInputValue(e.target.value);
    }
  };

  const onChangeBurnInputValue = e => {
    const value = Number(+e.target.value);
    if (!isNaN(value)) {
      setBurnInputValue(e.target.value);
    }
  };

  const checkApprove = async () => {
    if (isAllowedChainId) {
      if (!isLoadingCheckApprove) {
        setIsLoadingCheckApprove(true);

        const MIN_BALANCE = parseInt(MAX_VALUE, 16);
        try {
          const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
          const approveBalance = await contractBusd.allowance(
            account,
            config.pancakeExchange
          );

          const isAllowed = amount
            ? parseInt(approveBalance) >= parseInt(toWei(amount))
            : approveBalance >= MIN_BALANCE;

          if (isAllowed) {
            setIsNeedApprove(false);
          } else {
            setIsNeedApprove(true);
          }
        } catch (e) {
          setIsNeedApprove(true);
        }

        setIsLoadingCheckApprove(false);
      }
    }
  };

  useEffect(() => {
    if (amount && chosenTokens[0] === "BUSD" && isAllowedChainId) {
      checkApprove();
    } else {
      setIsNeedApprove(false);
    }
  }, [amount, chosenTokens?.[0], isAllowedChainId, account]);

  const callApprove = async () => {
    if (isAllowedChainId) {
      if (!isLoadingApprove) {
        setIsLoadingApprove(true);

        try {
          let result;
          if (chosenTokens[0] === "BUSD") {
            const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
            result = await contractBusd.approve(
              config.pancakeExchange,
              MAX_VALUE
            );
          }

          callNotification({
            type: "info",
            message: "Transaction was sent. Please wait",
            autoClose: 10000
          });

          setIsWaitingApprove(true);

          await result.wait();
        } catch (e) {
          setIsWaitingApprove(false);
          setIsLoadingApprove(false);
        }

        setIsWaitingApprove(false);
        setIsLoadingApprove(false);
        checkApprove();
      }
    }
  };

  const fetchSwapInfo = async () => {
    if (isAllowedChainId) {
      const isBusd = chosenTokens[0] === "BUSD";
      const contractAddr = isBusd
        ? contractsFetch.liquidityBUSD
        : contractsFetch.liquidityBNB;

      const contractPancake = await new Promise(function(resolve, rejected) {
        if (provider) {
          const contract = new Contract(
            contractAddr,
            liquidityAbi,
            provider?.getSigner(account).connectUnchecked() || provider
          );

          resolve(contract);
        } else {
          rejected("error init contract: ");
        }
      });

      const [reserve0, reserve1] = await contractPancake.getReserves();

      let price;

      if (isBusd) {
        price =
          transactionalNumberToNumber(reserve0.toString()) /
          transactionalNumberToNumber(reserve1.toString());
      } else {
        price =
          transactionalNumberToNumber(reserve1.toString()) /
          transactionalNumberToNumber(reserve0.toString());
      }

      setAmount(payInputValue * price);
    }
  };

  useEffect(() => {
    if (account && isAllowedChainId) {
      fetchBalance();
    }
  }, [account, chosenTokens, contractsFetch, isAllowedChainId]);

  useDebouncedEffect(
    async () => {
      if (
        account &&
        contractsFetch.liquidityBNB &&
        contractsFetch.liquidityBUSD
      ) {
        fetchSwapInfo();
      }
    },
    300,
    [payInputValue, account, chosenTokens[0], contractsFetch, isAllowedChainId]
  );

  const onSwapMain = async () => {
    if (isAllowedChainId && !!contractsFetch.frgxBSC) {
      const isBusdMode = chosenTokens[0] === "BUSD";
      if (!isLoadingTransactionSwap) {
        setIsLoadingTransactionSwap(true);

        try {
          const swapContractPancake = await getContract(
            CONTRACT_NAMES.PANCAKE_EXCHANGE
          );

          const contracts = isBusdMode
            ? [config.contractBusd, contractsFetch.frgxBSC]
            : [config.contractBnb, contractsFetch.frgxBSC];

          const totalPrice = parseFloat(payInputValue);

          const funcName = isBusdMode
            ? "swapExactTokensForTokens"
            : "swapExactETHForTokens";

          const params = isBusdMode
            ? [
                toWei(parseFloat(payInputValue)),
                toWei(amount * 0.985),
                contracts,
                account,
                getUnixTime(addMinutes(new Date(), 1))
              ]
            : [
                toWei(amount * 0.985),
                contracts,
                account,
                getUnixTime(addMinutes(new Date(), 1))
              ];

          const valueParams = {
            value: realNumberToTransactionalNumber(totalPrice)
          };

          const gas = await swapContractPancake.estimateGas[funcName](
            ...params,
            Object.assign({}, isBusdMode ? {} : valueParams)
          );

          const { wait } = await swapContractPancake[funcName](
            ...params,
            Object.assign(
              {
                gasLimit: gas ? gas.add(100000) : DEFAULT_GAS_LIMIT
              },
              isBusdMode ? {} : valueParams
            )
          );

          const swapBusdNotifLoading = callNotification({
            type: "info",
            message: `Swap to ${amount?.toFixed(
              5
            )} FRGX in progress. Please wait`,
            autoClose: 30000
          });

          setIsWaitingTransactionSwap(true);
          const waitInfo = await wait();

          toast.dismiss(swapBusdNotifLoading);

          if (waitInfo.status === 0) {
            callNotification({
              type: "error",
              message: "Transaction failed. Try again"
            });
          } else {
            callNotification({
              type: "success",
              message: `Swap to ${amount?.toFixed(5)} FRGX successful ✅`
            });
          }

          await fetchBalance();

          setTimeout(() => {
            setIsWaitingTransactionSwap(false);
            setIsLoadingTransactionSwap(false);

            fetchBalance();
          }, 1000);
        } catch (e) {
          setIsWaitingTransactionSwap(false);
          setIsLoadingTransactionSwap(false);
          callNotification({
            type: "error",
            message: parseErrorToUserReadableMessage(e)
          });
        }
      }
    }
  };

  const onChangeSelect = (value, index) => {
    if (index === 0) {
      return setChosenTokens([value, chosenTokens[1]]);
    } else {
      return setChosenTokens([chosenTokens[0], value]);
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  const burningFunc = async () => {
    try {
      let result;
      const contractFrgx = await getContract(CONTRACT_NAMES.FRGX);

      result = await contractFrgx.burn(toWei(burnInputValue));
      setBurnedFrgx(burnInputValue);
      onCallTransaction(result);

      callNotification({
        type: "info",
        message: "Transaction was sent. Please wait",
        autoClose: 10000
      });
    } catch (e) {
     console.log(e)
    }
  }

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      fetchBalance();
      onOpen();
    }
  }, [transactionInfo])

  const renderBurning = useMemo(() => {
    return (
        <div className="flex flex-col items-center space-y-8 w-full">
            <div className="flex flex-col items-start justify-between px-5 py-5 bg-white-60 rounded-[32px] border-[0.5px] border-white-200 h-[131px] min-w-[552px] w-[full] z-[11] sm:h-[96px] sm:px-3 sm:py-3 sm:min-w-full sm:rounded-[20px]">
              <div className="flex flex-col justify-between space-y-1 sm:space-y-0 sm:z-[111]">
                <span className="text-sm text-white-500 inter font-light leading-normal">You burn</span>
                <input
                  type="text"
                  value={burnInputValue}
                  onChange={onChangeBurnInputValue}
                  placeholder='Input burn value'
                  className="notranslate focus:outline-none text-white poppins bg-transparent poppins text-2xl h-[29px] w-[170px] font-medium placeholder-bg-white-60 sm:text-lg sm:w-full  "
                />
              </div>
              <div className="flex flex-col items-start justify-start space-y-2.5 sm:w-full sm:z-[111]">
                <span className={`notranslate text-xs text-white font-light text-white-300 ${balanceFrgx > burnInputValue ? '' : '!text-red'} `}>
                  Balance: {balanceFrgx}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center space-y-6 w-full max-w-[552px] sm:w-full sm:z-[111]">
             <button disabled={transactionInfo?.isWaiting || balanceFrgx < burnInputValue} onClick={() => burningFunc()} className="z-[3] notranslate bg-white w-full h-[52px] rounded-2xl sm:h-[48px] sm:w-full">
               <span className="notranslate text-xl text-black font-medium sm:text-lg">{transactionInfo?.isWaiting ? 'Waiting...' : 'Burning'}</span>
             </button>
           </div>
          </div>
    );
  }, [burnInputValue, balanceFrgx, transactionInfo]);

  const renderSwap = useMemo(() => {
    return (
      <div className="flex flex-col items-center justify-center z-[11] h-full space-y-8 w-full">
        <div className="relative flex flex-col justify-center w-full h-full space-y-4 sm:space-y-1 sm:justify-end">
            <div className="flex justify-between px-5 py-5 bg-white-60 rounded-[32px] mb-[2.5px] border-[0.5px] border-white-200 h-[131px] sm:rounded-[20px] sm:h-[96px] sm:px-3 sm:py-3 sm:mb-[2px] sm:border-none">
              <div className="flex flex-col justify-between space-y-1 sm:space-y-0">
                <span className="text-sm text-white-500 inter font-light leading-normal">You pay</span>
                <input
                  type="text"
                  value={payInputValue}
                  onChange={onChangePayInputValue}
                  placeholder={payInputValue}
                  className="notranslate focus:outline-none text-white poppins bg-transparent poppins text-2xl h-[29px] w-[170px] font-medium  placeholder-bg-white-60 sm:text-lg sm:leading-normal  "
                />
                <span className="text-white-500 poppins font-light pt-1 sm:text-sm">$6,800.58</span>
              </div>
              <div className="flex flex-col items-center justify-center space-y-2.5">
                <Select
                  onChange={value => onChangeSelect(value, 0)}
                  value={chosenTokens[0]}
                  className="notranslate"
                  data={[
                    {
                      icon: "/icons/tokens/BUSD.svg",
                      title: "BUSD"
                    },
                    {
                      icon: "/icons/tokens/BNB.svg",
                      title: "BNB"
                    }
                  ]}
                />
                {/* <span className="notranslate text-sm text-white font-light text-white-300">
                  Balance: {balanceMapper[chosenTokens[0]]?.toFixed(4)}
                </span> */}
              </div>
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 top-[100px] sm:top-[80px] swap_arrow_button flex items-center justify-center bg-[#0C0C0C] w-[45px] h-[45px] rounded-full sm:h-[32px] sm:w-[32px] sm:top-[145px]">
              <img className="w-[20px] h-[20px]" src="/icons/arrowBot.svg" />
          </div>
          <div className="flex justify-between px-5 py-5 bg-white-60 rounded-[32px] border-[0.5px] border-white-200 h-[131px]  sm:rounded-[20px] sm:h-[96px] sm:px-3 sm:py-3  sm:border-none sm:mt-0">
            <div className="flex flex-col justify-between ">
              <span className="text-sm text-white-500 inter font-light leading-normal">You recive</span>
              <input
                type="text"
                disabled
                value={amount}
                placeholder={amount}
                className="notranslate focus:outline-none text-white poppins bg-transparent poppins text-2xl h-[29px] w-[170px] font-medium  placeholder-bg-white-60 sm:text-lg "
              />
            </div>
            <div className="flex flex-col items-center justify-center space-y-2.5">
              <Select
                onChange={value => onChangeSelect(value, 1)}
                value={chosenTokens[1]}
                className="notranslate"
                data={[
                  {
                    icon: "/icons/tokens/FRGX.png",
                    title: "FRGX"
                  }
                ]}
              />
              {/* <span className="notranslate text-sm text-white font-light text-white-300">
                Balance: {balanceMapper[chosenTokens[1]]?.toFixed(4)}
              </span> */}
            </div>   
          </div>
        </div>

        {isOpen ? (
          <div className="flex flex-col items-center justify-center space-y-6 w-full max-w-[552px]">
            <button
              onClick={() => {
                if (isNeedApprove) {
                  callApprove();
                } else {
                  onSwapMain();
                }
              }}
              className="notranslate bg-white w-full h-[52px] rounded-2xl sm:h-[48px]"
              disabled={
                !isCompletedCountdown ||
                !contractsFetch.frgxBSC ||
                isLoadingTransactionSwap ||
                isWaitingTransactionSwap ||
                isLoadingApprove ||
                isWaitingApprove ||
                isLoadingCheckApprove
              }
            >
              <span className="notranslate text-xl text-black font-medium sm:text-sm">
                {isLoadingTransactionSwap ||
                isWaitingTransactionSwap ||
                isLoadingApprove ||
                isWaitingApprove ||
                isLoadingCheckApprove
                  ? "Loading..."
                  : isNeedApprove
                  ? "Approve"
                  : "Swap"}
              </span>
            </button>
            <button onClick={() => setIsOpen(!isOpen)}>
              <span className="notranslate text-sm text-white-700 font-light">
                For <span className="text-[#F24A33]">SELL FRGX </span>go
                to&nbsp;
                <span className="underline">pancakeswap</span>
              </span>
            </button>
          </div>
        ) : (
          <div className="relative flex flex-col items-center justify-between p-4 h-full bg-[#353535] space-y-[17px] backdrop-blur-small rounded-3xl border-[1px] border-white-100 ">
            <span className="text-white font-light">
              We set the maximum slippage to ensure the transaction is
              successful. if you want to control the slippage - go to
              pancakeswap
            </span>
            {!!contractsFetch.frgxBSC && (
              <a
                target="_blank"
                href={`https://pancakeswap.finance/swap?inputCurrency=${contractsFetch.frgxBSC}&outputCurrency=${chosenTokens[0]}`}
                className="flex items-center justify-center bg-lightGreen rounded-[50px] py-[9px] w-[221px] border-[1px] border-[#96F233]"
                rel="noreferrer"
              >
                <span className="text-white font-medium ">
                  Go to Pancake.swap
                </span>
              </a>
            )}

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="absolute p-1 right-4 top-0 rounded-full bg-white-100"
            >
              <img
                className="notranslate w-3 h-3"
                src="/icons/closeIcon2.svg"
              />
            </button>
          </div>
        )}

        {!isCompletedCountdown && (
          <CustomTimer
            isCompletedCountdown={isCompletedCountdown}
            setIsCompletedCountdown={setIsCompletedCountdown}
          />
        )}
        {isCompletedCountdown && account && !isAllowedChainId && (
          <ConnectWallet changeNetwork={!isAllowedChainId} />
        )}
        {isCompletedCountdown && !account && <ConnectWallet />}
      </div>
    );
  }, [
    isLoadingTransactionSwap,
    chosenTokens,
    balanceMapper,
    amount,
    payInputValue,
    isNeedApprove,
    isCompletedCountdown,
    isWaitingApprove,
    isLoadingApprove,
    isWaitingTransactionSwap,
    isCompletedThirtyCountdown,
    contractsFetch
  ]);

  const tokenActions = [
    {
      title: '🤑 Swap',
      content: [
        {
         title: <span>Swap tokens <br /> ON BNB Chain</span>,
         topShadow: 'swap/topShadow.png',
         leftShadow: 'swap/leftShadow.png',
         icon: 'swap/pancakeswap.png',
         main: renderSwap,
        }
      ],
    },
    {
      title: '🔥 Burning',
      content: [
        {
          title: 'BURNING TOKENS',
          topShadow: 'burning/topShadow.png',
          leftShadow: 'burning/leftShadow.png',
          icon: 'burning/burningIcon.png',
          main: renderBurning,
        }
      ],
    },
  ]

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className="w-full max-w-[1500px] relative px-[108px] sm:px-4">
      <div
        className="flex relative items-center pt-[159px] flex-col z-[1] sm:w-full sm:pt-[0px]"
        ref={customRef}
      >
        <div className="flex flex-col justify-around w-full pt-[80px] sm:flex-col sm:relative sm:pt-[100px] space-y-3 sm:pt-[0px]">
          <div className="flex w-full h-[68px] rounded-3xl border-[1px] border-white-500 p-2">
            {tokenActions.map((item, itemIndex) => {
              const isActive = itemIndex === currentTab;
              return (
              <button onClick={() => {setCurrentTab(itemIndex)}} className={`flex items-center justify-center ${isActive ? 'swap_bg' : ''} w-full h-full rounded-2xl`}>
                <span className="text-white text-xl font-medium sm:text-sm">{item?.title}</span>
              </button>
              )
            })}
          </div>
              {tokenActions[currentTab].content.map((item, itemIndex) => {
                return (
                  <div className="flex justify-between items-center relative space-x-3 sm:flex-col h-full max-w-[1224px] min-h-[562px] w-full z-[1] sm:w-full sm:h-full h-full rounded-tr-3xl rounded-b-3xl px-[60px] pt-6 pb-[29px]  backdrop-blur-small overflow-hidden sm:rounded-2xl sm:px-3 sm:h-[629px] sm:pt-[72px] sm:space-x-0">
                    <div className="flex flex-col space-y-[20px] justify-center w-full text-3xl text-white z-[1] sm:space-y-3 sm:items-center sm:z-[1111]">
                      <img className="w-[65px]" src={`/images/swapTokens/${item?.icon}`}/>
                      <span className="max-w-[390px] max-w-full text-[50px] leading-[60px] text-white poppins font-semibold sm:text-2xl sm:leading-[28.8px]">{item?.title}</span>
                      <span className="max-w-[318px] max-w-full text-white-400 text-[16px] font-light leading-normal inter sm:text-sm sm:text-center">
                        {currentTab === 0 ? 'Connect your wallet and buy FRGX tokens in BNB Chain for BNB or BUSD' : 'Simply burn some FRGX on the BNB Chain and support the community!'}
                      </span>
                    </div>
                    {item?.main}
                    <img className="absolute top-0 z-[1] sm:hidden" src={`/images/swapTokens/${item?.topShadow}`}/>
                    <img className="absolute top-0 left-0 z-[0] sm:hidden" src={`/images/swapTokens/${item?.leftShadow}`}/>
                    <img className="absolute bottom-0 left-0 z-[1] hidden sm:flex" src={`/images/swapTokens/swap/leftShadowMob.png`}/>
                    <img className="absolute top-0 left-0 w-full z-[1] hidden sm:flex" src={`/images/swapTokens/swap/topShadowMob.png`}/>
                  </div>
                  )
                })}
          </div>
      </div>
      <BurnResultModal value={burnedFrgx} transactionLink={'https://bscscan.com/tx/' + transactionInfo?.hash} openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
