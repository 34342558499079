import React, { useState } from "react";

// timer
import { SWAP_TOKEN_START } from "../../../helpers/constants";
import { isBefore } from 'date-fns';
import clsx from "clsx"; 
import { copy } from "../../../helpers/text";
    
export const TokenInfo = ({ swapTokensRef, roadMapRef }) => {   
     // timer
    
    const [isCompletedCountdown, setIsCompletedCountdown] = useState(!isBefore(new Date(), new Date(SWAP_TOKEN_START)));


    const scrollToRef = (currentRef) => {
        window.scrollTo({ top: currentRef?.current?.offsetTop, behavior: 'smooth' });
    };

     // timer end


    const button = (link, text, classNameWrapper, className) => {
        return (
            <div className={clsx(`token-buy-border h-[52px] rounded-3xl p-[1px] z-[11]`, classNameWrapper)}>
                <a href={link} target='_blank' className={clsx(`flex items-center justify-center bg-black w-full h-full rounded-3xl text-white text-2xl font-semibold`, className)} rel="noreferrer">
                    <span className="text-white text-lg font-medium sm:text-base">{text}</span>
                </a>
            </div>
        )
    }
    const staticsInfo = [
        {
            title: 'Token Price',
            desc: '$0.2',
        },
        {
            title: 'Market Cap',
            desc: '$2,05M',
        },
        {
            title: 'Holders',
            desc: '40K',
        },
    ]

    return (
        <div className="flex flex-col w-full max-w-[1500px] sm:px-0 h-full">
            <div className="flex flex-col items-center justify-center w-full h-full ">
                <div className="flex flex-col items-center pt-[180px] z-[1]">
                    <div className="flex space-x-6 sm:space-x-3 ">
                        <span className="main_frgx_title text-[140px] font-medium leading-normal sm:text-[40px]">FRGX</span>
                        <span className="text-[140px] text-white font-medium leading-normal sm:text-[40px]">Finance</span>
                    </div>
                    <span className="main_frgx_desc font-light max-w-[570px] text-center inter sm:text-sm sm:max-w-[300px]">FRGX Finance is a self-managed token on Base and BNB Chain that has utility value in a number of successful DeFi products</span>
                    <div className="flex space-x-3 mt-12 sm:flex-col sm:space-x-0 sm:space-y-3">
                        <a target="blank" href="https://pancakeswap.finance/swap?inputCurrency=0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c&outputCurrency=BNB" className="flex items-center justify-center w-[152px] h-[52px] bg-white rounded-[36px] sm:w-[230px]">
                            <span className="text-black poppins font-semibold">BUY $FRGX</span>
                        </a>
                        <a target="blank" href="https://portalbridge.com/advanced-tools/#/transfer?sourceChain=bsc&targetChain=base" className="flex items-center justify-center w-[230px] h-[52px] transfer_base_button rounded-[36px] space-x-[10px] sm:w-[230px]">
                            <span className="text-white poppins">Transfer to BASE</span>
                            <img src="/icons/arrow_right.svg"/>
                        </a>

                    </div>
                </div>
                <div className="flex items-center justify-center z-[1] w-full rounded-full sm:flex-col sm:space-x-0 sm:space-y-3 sm:w-full pt-[230px] sm:pt-[130px]">
                        <div className="flex justify-evenly w-full">
                            {staticsInfo.map((item, itemIndex) => {
                                return (
                                        <div className="z-[11] flex flex-col items-center justify-center relative h-full w-full w-[245px] h-[94px] sm:h-[64px]">
                                            <span className="text-[60px] text-white poppins z-[1] leading-normal sm:text-3xl">{item?.desc}</span>
                                            <span className="text-white inter font-light z-[1] leading-[23.6px] sm:text-xs">{item?.title}</span>
                                        </div>
                                )
                                })}
                        </div>
                         <img className="absolute left-0 z-[0] w-full h-[213px] sm:hidden" src="/images/mainPage/union.png" />
                         <img className="absolute left-0 z-[0] w-full h-[213px] hidden sm:flex sm:h-[105px]" src="/images/mainPage/unionMob.png" />
                </div>
                <div className="flex flex-col items-center mt-[120px] relative sm:w-full">
                    <img className="sm:hidden" src="/images/mainPage/smartContract/picture.png"/>
                    <img className="hidden sm:flex sm:w-full sm:max-w-[450px]" src="/images/mainPage/smartContract/mobPicture.png"/>
                    <div className="flex flex-col items-center justify-center absolute top-0 left-1/2 -translate-x-1/2 top-[270px] sm:w-full sm:top-[120px] sm:px-4">
                        <img className="w-[56px] h-[69px]" src="/images/mainPage/smartContract/stars.png"/>
                        <button onClick={() => copy('0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c')} className="flex items-center justify-center smart_contract_bg w-[500px] h-[50px] rounded-[36px] space-x-[10px] mt-[47px] sm:w-full sm:mt-[40px]">
                            <span className="text-white font-light leading-5 sm:hidden">0xC703dA39aE3B9Db67C207c7Bad8100E1AfdC0F9c</span>
                            <span className="text-white font-light leading-5 hidden sm:flex">0xC703dA...........E1AfdC0F9c</span>
                            <img src="/icons/arrow_right.svg"/>
                        </button>
                        <span className="text-[60px] text-white smart_contract_text font-medium leading-none mt-[40px] sm:text-[30px] sm:mt-6">Smart Contract</span>
                    </div>
                </div>
            </div>
            <img className="absolute left-0 z-[0] top-0 sm:h-[600px]" src="/images/mainPage/shadow.png" />

        </div>
    )
}