import React from "react";
import { Item } from "./item";

export const Reviews = () => {
    return (
         <div className="flex items-center justify-center w-full relative">
            <div className="w-full max-w-[1500px] px-[108px] sm:px-0 relative z-[1]">
               <div className="relative flex flex-col items-start space-y-[60px] relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] mt-[216px] sm:mt-[80px] sm:items-center sm:space-y-10 ">
                   <h2 className="text-[50px] poppins text-white font-semibold uppercase sm:text-[30px] sm:text-center">Feedback from <br className="hidden sm:flex" /> our holders</h2>
                        <div className="flex space-x-6 h-[812px] sm:h-[492px] sm:space-x-2 z-[111]">
                            <div className="flex flex-col space-y-6 sm:space-y-2">
                                <Item ava={"1"} name={"Kofi Rahman"} desc={"FRGX has been a game-changer for me! We are all looking forward to the expansion of the ecosystem and new useful tools, and we can't wait to see the token price and team size grow even more. Huge thanks to the developers!"}  />
                                <Item ava={"2"} name={"AmaraBah99"} desc={"FRGX totally stands out with its full-on decentralization and no reserved coins for the creators. And let's talk about their commitment to fairness and keeping things transparent — it's what drew me and over 40,000 other folks into the mix!"}  />
                                <Item ava={"3"} name={"Masood Khan"} desc={"FRGX is an amazing project. At first, I was skeptical of this unknown project, but then I got to know the community better, where there are many cool guys who opened up the world of cryptocurrencies to me."}  />
                            </div>
                            <div className="flex flex-col space-y-6 sm:space-y-2">
                                <Item ava={"4"} name={"MensahKwame"} desc={"The fact that FRGX hit a $2 million daily trading volume says it all — the community's totally on board with this project. Holding onto FRGX isn't just about the rewards — it's a smart move for the future."}  />
                                <Item ava={"5"} name={"Oskarsson Bjorn"} desc={"Hold, hold, and hold again. We are just in the beginning.... $FRGX #FRGX"}  />
                                <Item ava={"6"} name={"Yara Mahmoud"} desc={"Staking my FRGX tokens has been a blast! Loving those rewards, and the fact that the project's all about utility and building up the ecosystem? Total confidence booster. Can't wait to see what other cool stuff they roll out with FRGX!"}  />
                            </div>
                            <div className="flex flex-col space-y-6 sm:hidden">
                                <Item ava={"7"} name={"Mikaelsson Berg"} desc={"Hey! I am extremely overwhelmed to announce that I am one of thousands holders of 'FRGX' utility token.Why we believe in frgx- Frgx was just launched three months ago and it showed its potential for growth. We are coming up with many new projects. So of course I do believe in Devs. Keep it up"}  />
                                <Item ava={"8"} name={"WeiChen"} desc={"Man, FRGX totally changed the game for me! I got in early with the crew, and let me tell you, their commitment to keeping things decentralized is seriously inspiring. Can't wait to see it climb up to the top of the BNB charts by 2024!"}  />
                                <Item ava={"9"} name={"Yara Mahmoud"} desc={"Having observed the innovative strides made by the FRGX, it's evident that they possess a wealth of experience in crafting contemporary financial products. As an investor in FRGX, I've witnessed firsthand the project's remarkable growth trajectory and am thoroughly impressed."}  />
                            </div>
                       </div>
                   </div>  
               </div>
            <img className="absolute right-0 w-full z-[0] h-full top-20 sm:hidden" src="/images/feedBack/shadow.png"/>
            <img className="absolute bottom-0 w-full z-[1] sm:h-[70px]" src="/images/feedBack/bottomShadow.png"/>
            <img className="hidden sm:flex absolute right-0 w-full z-[1] h-full top-20" src="/images/feedBack/shadowMob.png"/>
         </div>
    )
}