import React from "react";
import { Modal } from "../../Modal";

export const BurnResultModal = ({ openedModal, handleCloseModal, value = 0, transactionLink = '' }) => {

    const gradientStyle = {
        backgroundImage: `url('/images/burnResultModal/bg-gradient.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }

    return (
        <Modal isOpened={openedModal} onClose={handleCloseModal}>
            <div className="overflow-hidden relative flex flex-col items-center justify-center space-y-5 bg-[#000000] w-[392px] h-[562px] rounded-3xl sm:rounded-none sm:rounded-t-3xl pt-[19px] pb-10 sm:w-full px-5">
                <div style={gradientStyle} className="z-[0] absolute top-0 left-0 w-full h-full"></div>
                <img className="z-[2] h-[128px]" src="/images/burnResultModal/fire.png" alt="" />
                <div className="z-[2] flex flex-col items-center justify-center text-white">
                    <span className="text-[20px]">You’ve burned </span>
                    <span className="text-[40px] font-semibold">{value} FRGX</span>
                </div>
                <span className="z-[2] text-white-500 text-center">This is very valuable for the community. The lower the emission, the higher is the token price!</span>
                <a className="z-[2] cursor-pointer hover:opacity-75 bg-white rounded-[16px] w-full text-black flex items-center justify-center py-4 font-semibold" href={transactionLink} target="_blank">View transaction</a>
            </div>
        </Modal>
    );
};
