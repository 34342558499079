import { useWeb3React } from "@web3-react/core";
import { useGetContract } from "../../../helpers/hooks/useGetContract";
import React, { useEffect, useMemo, useState } from "react";
import { addMinutes, getUnixTime, isBefore } from "date-fns";
import {
  BUY_LIST,
  CONTRACT_NAMES,
  DEFAULT_GAS_LIMIT,
  FIRST_THIRTY_DATE,
  MAX_VALUE,
  SWAP_TOKEN_START
} from "../../../helpers/constants";
import { useBalance } from "../../../helpers/hooks/useBalance";
import config from "../../../helpers/config";
import { toWei } from "../../../helpers/numbers";
import { callNotification } from "../../../helpers/notification";
import { Contract } from "@ethersproject/contracts";
import { liquidityAbi } from "../../../helpers/contractsAbi/liquidityAbi";
import { useDebouncedEffect } from "../../../helpers/hooks/useDebounce";
import { toast } from "react-toastify";
import { parseErrorToUserReadableMessage } from "../../../helpers/format";
import { Select } from "../../../components";
import { motion } from "framer-motion";
import { realNumberToTransactionalNumber } from "./MainSwap";
import BigNumber from "bignumber.js";

import { ConnectWallet } from "./comp/ConnectWallet";
import { CustomTimer } from "./comp/CustomTimer";

export function transactionalNumberToNumber(amount, decimals = 18) {
  return new BigNumber(amount, 10)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toNumber();
}

export const ThirtySwap = ({
  contractsFetch,
  customRef,
  isRegistered = false
}) => {
  const { account, provider, chainId } = useWeb3React();
  const { getContract } = useGetContract();
  const isAllowedChainId = Number(chainId) === Number(config.allowedChainId);

  const [pricePair, setPricePair] = useState(0);
  // timer

  const [isCompletedCountdown, setIsCompletedCountdown] = useState(
    !isBefore(new Date(), new Date(SWAP_TOKEN_START))
  );

  // timer end

  // timer for first 30 min

  const [isCompletedThirtyCountdown, setIsCompletedThirtyCountdown] = useState(
    !isBefore(new Date(), new Date(FIRST_THIRTY_DATE))
  );

  const [payInputValue, setPayInputValue] = useState(10);
  const [amount, setAmount] = useState(BUY_LIST[0].value);

  const [isLoadingTransactionSwap, setIsLoadingTransactionSwap] = useState(
    false
  );
  const [isWaitingTransactionSwap, setIsWaitingTransactionSwap] = useState(
    false
  );
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isWaitingApprove, setIsWaitingApprove] = useState(false);
  const [isLoadingCheckApprove, setIsLoadingCheckApprove] = useState(false);
  const [isNeedApprove, setIsNeedApprove] = useState(false);
  const [chosenTokens, setChosenTokens] = useState(["BNB", "FRGX"]);

  const {
    balanceBnb = 0,
    balanceBusd = 0,
    balanceFrgx = 0,
    fetchBalance
  } = useBalance({ contractsFetch, withoutFixed: true });

  const balanceMapper = {
    BUSD: balanceBusd,
    BNB: balanceBnb,
    FRGX: balanceFrgx
  };

  const checkApprove = async () => {
    if (isAllowedChainId) {
      if (!isLoadingCheckApprove) {
        setIsLoadingCheckApprove(true);
        const MIN_BALANCE = parseInt(MAX_VALUE, 16);
        try {
          const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
          const approveBalance = await contractBusd.allowance(
            account,
            config.pancakeExchange
          );

          const isAllowed = amount
            ? parseInt(approveBalance) >= parseInt(toWei(amount))
            : approveBalance >= MIN_BALANCE;

          if (isAllowed) {
            setIsNeedApprove(false);
          } else {
            setIsNeedApprove(true);
          }
        } catch (e) {
          setIsNeedApprove(true);
        }
      }
      setIsLoadingCheckApprove(false);
    }
  };

  useEffect(() => {
    if (amount && chosenTokens[0] === "BUSD" && isAllowedChainId) {
      checkApprove();
    } else {
      setIsNeedApprove(false);
    }
  }, [amount, chosenTokens?.[0], contractsFetch, isAllowedChainId, account]);

  const callApprove = async () => {
    if (isAllowedChainId) {
      if (!isLoadingApprove) {
        setIsLoadingApprove(true);

        try {
          const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
          const result = await contractBusd.approve(
            config.pancakeExchange,
            MAX_VALUE
          );
          callNotification({
            type: "info",
            message: "Transaction was sent. Please wait",
            autoClose: 10000
          });

          setIsWaitingApprove(true);

          await result.wait();
        } catch (e) {
          setIsWaitingApprove(false);
          setIsLoadingApprove(false);
        }

        setIsWaitingApprove(false);
        setIsLoadingApprove(false);
        checkApprove();
      }
    }
  };

  const fetchSwapInfo = async () => {
    if (isAllowedChainId && !!contractsFetch.frgxBSC) {
      const isBusd = chosenTokens[0] === "BUSD";
      const contractAddr = isBusd
        ? contractsFetch.liquidityBUSD
        : contractsFetch.liquidityBNB;

      const contractPancake = await new Promise(function(resolve, rejected) {
        if (provider) {
          const contract = new Contract(
            contractAddr,
            liquidityAbi,
            provider?.getSigner(account).connectUnchecked() || provider
          );

          resolve(contract);
        } else {
          rejected("error init contract: ");
        }
      });

      const [reserve0, reserve1] = await contractPancake.getReserves();

      let price;

      if (isBusd) {
        price =
          transactionalNumberToNumber(reserve1.toString()) /
          transactionalNumberToNumber(reserve0.toString());
      } else {
        price =
          transactionalNumberToNumber(reserve0.toString()) /
          transactionalNumberToNumber(reserve1.toString());
      }

      setPricePair(price);
    }
  };

  useEffect(() => {
    if (account && isAllowedChainId) {
      fetchBalance();
    }
  }, [account, chosenTokens, contractsFetch, isAllowedChainId]);

  useDebouncedEffect(
    async () => {
      if (
        account &&
        contractsFetch.liquidityBNB &&
        contractsFetch.liquidityBUSD &&
        isAllowedChainId
      ) {
        fetchSwapInfo();
      }
    },
    300,
    [amount, account, chosenTokens[0], contractsFetch, isAllowedChainId]
  );

  const onSwap = async () => {
    const isBusdMode = chosenTokens[0] === "BUSD";
    if (!isLoadingTransactionSwap) {
      setIsLoadingTransactionSwap(true);

      try {
        const swapContractPancake = await getContract(
          CONTRACT_NAMES.PANCAKE_EXCHANGE
        );

        const contracts = isBusdMode
          ? [config.contractBusd, contractsFetch.frgxBSC]
          : [config.contractBnb, contractsFetch.frgxBSC];

        const totalPrice = amount * pricePair * contractsFetch.multiplier;

        const funcName = isBusdMode
          ? "swapTokensForExactTokens"
          : "swapETHForExactTokens";

        const params = isBusdMode
          ? [
              toWei(amount),
              realNumberToTransactionalNumber(totalPrice, 18, 0),
              contracts,
              account,
              getUnixTime(addMinutes(new Date(), 1))
            ]
          : [
              toWei(amount),
              contracts,
              account,
              getUnixTime(addMinutes(new Date(), 1))
            ];

        const valueParams = {
          value: realNumberToTransactionalNumber(totalPrice, 18, 0)
        };

        const gas = await swapContractPancake.estimateGas[funcName](
          ...params,
          Object.assign({}, isBusdMode ? {} : valueParams)
        );

        const { wait } = await swapContractPancake[funcName](
          ...params,
          Object.assign(
            {
              gasLimit: gas ? gas.add(100000) : DEFAULT_GAS_LIMIT
            },
            isBusdMode ? {} : valueParams
          )
        );

        const swapBusdNotifLoading = callNotification({
          type: "info",
          message: `Swap to ${amount?.toFixed(
            5
          )} FRGX in progress. Please wait`,
          autoClose: 30000
        });

        setIsWaitingTransactionSwap(true);
        const waitInfo = await wait();

        toast.dismiss(swapBusdNotifLoading);

        if (waitInfo.status === 0) {
          callNotification({
            type: "error",
            message: "Transaction failed. Try again"
          });
        } else {
          callNotification({
            type: "success",
            message: `Swap to ${amount?.toFixed(5)} FRGX successful ✅`
          });
        }

        await fetchBalance();

        setTimeout(() => {
          setIsWaitingTransactionSwap(false);
          setIsLoadingTransactionSwap(false);

          fetchBalance();
        }, 1000);
      } catch (e) {
        setIsWaitingTransactionSwap(false);
        setIsLoadingTransactionSwap(false);
        callNotification({
          type: "error",
          message: parseErrorToUserReadableMessage(e)
        });
      }
    }
  };

  const onChangeSelect = (value, index) => {
    if (index === 0) {
      return setChosenTokens([value, chosenTokens[1]]);
    } else {
      return setChosenTokens([chosenTokens[0], value]);
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  const renderSwap = useMemo(() => {
    const stylesProps = {
      backgroundImage: `url(/images/swap/timer/bg.svg)`,
      backgroundSize: "cover"
    };

    return (
      <>
        <div className="relative flex flex-col">
          <div className="flex justify-between px-5 py-2.5 bg-[#0C0C0C] rounded-2xl space-y-[5px]">
            <div className="flex flex-col justify-between space-y-2.5">
              <span className="text-sm text-white font-light">
                Choose token value
              </span>
              <Select
                onChange={amount => setAmount(amount)}
                value={amount}
                className="notranslate"
                data={BUY_LIST}
              />
            </div>
            <div className="flex flex-col items-end justify-end space-y-2.5">
              <span className="text-sm text-white font-light text-white-300">
                Balance: {balanceMapper[chosenTokens[1]]?.toFixed(4)}
              </span>
              <Select
                onChange={value => onChangeSelect(value, 1)}
                value={chosenTokens[1]}
                className="notranslate"
                data={[
                  {
                    icon: "/icons/tokens/FRGX.png",
                    title: "FRGX"
                  }
                ]}
              />
            </div>
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex items-center justify-center bg-[#0C0C0C] w-[45px] h-[45px] rounded-full">
            <div className="flex items-center justify-center bg-[#96F233] rounded-full w-[32px] h-[32px]">
              <img src="/icons/arrowBottom.svg" />
            </div>
          </div>
          <div className="flex justify-between px-5 py-2.5 bg-[#0C0C0C] rounded-2xl mb-[2.5px] mt-[2.5px]">
            <div className="flex flex-col justify-between space-y-2.5">
              <span className="text-sm text-white font-light">You pay</span>
              <input
                type="text"
                disabled={true}
                value={realNumberToTransactionalNumber(
                  amount * pricePair * contractsFetch.multiplier,
                  0
                )}
                className="focus:outline-none bg-[#0C0C0C] text-[#96F233] poppins text-[32px] h-[48px] w-[170px] font-medium  placeholder-[#96F233] "
              />
            </div>
            <div className="flex flex-col items-end justify-end space-y-2.5">
              <span className="text-sm text-white font-light text-white-300">
                Balance: {balanceMapper[chosenTokens[0]]?.toFixed(4)}
              </span>
              <Select
                onChange={value => onChangeSelect(value, 0)}
                value={chosenTokens[0]}
                className="notranslate"
                data={[
                  {
                    icon: "/icons/tokens/BUSD.svg",
                    title: "BUSD"
                  },
                  {
                    icon: "/icons/tokens/BNB.svg",
                    title: "BNB"
                  }
                ]}
              />
            </div>
          </div>
        </div>

        {isOpen ? (
          <div className="flex flex-col items-center justify-center space-y-6">
            <button
              onClick={() => {
                if (isNeedApprove) {
                  callApprove();
                } else {
                  onSwap();
                }
              }}
              className="bg-[#96F233] w-full h-[66px] rounded-full sm:h-[48px]"
              disabled={
                !isCompletedCountdown ||
                !contractsFetch.frgxBSC ||
                isLoadingTransactionSwap ||
                isWaitingTransactionSwap ||
                isLoadingApprove ||
                isWaitingApprove
              }
            >
              <span className="text-xl text-black font-medium sm:text-lg">
                {isLoadingTransactionSwap ||
                isWaitingTransactionSwap ||
                isLoadingApprove ||
                isWaitingApprove
                  ? "Loading..."
                  : isNeedApprove
                  ? "Approve"
                  : "Swap"}
              </span>
            </button>
            <button onClick={() => setIsOpen(!isOpen)}>
              <span className="text-sm text-white-700 font-light">
                For <span className="text-[#F24A33]">SELL FRGX </span>go to{" "}
                <span className="underline">pancake.swap/token'</span>
              </span>
            </button>
          </div>
        ) : (
          <div className="relative flex flex-col items-center justify-between p-4 h-full bg-[#353535] space-y-[17px] backdrop-blur-small rounded-3xl border-[1px] border-white-100 ">
            <span className="text-white font-light">
              We set the maximum slippage to ensure the transaction is
              successful. If you want to control slippage - pancake.swap/token'
            </span>
            {!!contractsFetch.frgxBSC && (
              <a
                target="_blank"
                href={`https://pancakeswap.finance/swap?inputCurrency=${contractsFetch.frgxBSC}&outputCurrency=${chosenTokens[0]}`}
                className="flex items-center justify-center bg-lightGreen rounded-[50px] py-[9px] w-[221px] border-[1px] border-[#96F233]"
              >
                <span className="text-white font-medium ">
                  Go to Pancake.swap
                </span>
              </a>
            )}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="absolute p-1 right-4 top-0 rounded-full bg-white-100"
            >
              <img className="w-3 h-3" src="/icons/closeIcon2.svg" />
            </button>
          </div>
        )}

        {!isCompletedCountdown && (
          <CustomTimer
            isCompletedCountdown={isCompletedCountdown}
            setIsCompletedCountdown={setIsCompletedCountdown}
          />
        )}
        {isCompletedCountdown && account && !isAllowedChainId && (
          <ConnectWallet changeNetwork={!isAllowedChainId} />
        )}
        {isCompletedCountdown && !account && <ConnectWallet />}
      </>
    );
  }, [
    isLoadingTransactionSwap,
    chosenTokens,
    balanceMapper,
    amount,
    payInputValue,
    isNeedApprove,
    isCompletedCountdown,
    isWaitingApprove,
    isLoadingApprove,
    isWaitingTransactionSwap,
    isCompletedThirtyCountdown,
    isAllowedChainId,
    contractsFetch
  ]);

  const loadingBlock = () => {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <img
          className="max-w-full w-full"
          src={"/images/swap/static_animatioin.png"}
        />
      </div>
    );
  };

  return (
    <div className="w-full max-w-[1500px]">
      <div
        className="flex relative items-center mt-[159px] flex-col z-[1]"
        ref={customRef}
      >
        <h2 className="text-center text-[140px] title-text-color font-semibold sm:text-[40px]">
          Swap tokens
        </h2>
        <div className="flex  justify-around w-full pt-[80px] sm:flex-col sm:relative sm:pt-[190px]">
          <div className="w-[500px] h-[500px] sm:max-w-[350px] sm:w-full sm:h-[95vw] sm:absolute sm:left-1/2 sm:-translate-x-1/2 sm:top-[3%] z-[0]">
            {/* {isMobile ? loadingBlock() : (
                      <Suspense fallback={loadingBlock()}>
                          <spline-viewer> scene="https://prod.spline.design/5Uf5sNuM9NyCjqGk/scene.splinecode" />
                      </Suspense>
                  )} */}
            <motion.img
              animate={{ rotate: 360 }}
              transition={{ duration: 5, repeat: Infinity }}
              className="max-w-full w-full"
              src={"/images/swap/static_animation.png"}
            />
          </div>
          <div className="relative z-[1] flex flex-col justify-between w-[496px] sm:w-full sm:h-full h-full sm:min-h-[400px] rounded-3xl bg-white-30 px-6 pt-6 pb-[29px] sm:rounded-none space-y-[30px] border-[1px] border-white-90 backdrop-blur-small overflow-hidden">
            {renderSwap}
          </div>
          <img
            className="absolute z-[-1] h-[1200px] w-[1200px] !max-w-none top-1/2 -translate-y-[31%] right-[-20%] sm:left-1/2 sm:right-auto sm:-translate-x-1/2 sm:-translate-y-1/2"
            src="/images/swap/shadow.png"
            alt="swap_shadow"
          />
        </div>
      </div>
    </div>
  );
};
