import React, {useEffect, useState, useMemo} from "react";
import { ActivateModal } from "../../../../components";
import { useModal } from "../../../../helpers/hooks/useModal";

export const ConnectWallet = ({ changeNetwork = false }) => {
  const stylesProps = {
    backgroundImage: `url(/images/swap/timer/bg.svg)`,
    backgroundSize: "cover"
  };

  const { openedModal, onOpen, onClose } = useModal();
  if (changeNetwork) {
    return (
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center !mt-0 backdrop-blur-sm">
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={stylesProps}
        ></div>
        <div className="flex flex-col items-center justify-center text-white text-4xl font-semibold z-[2] w-full space-y-2.5 pb-6">
          
          <button  className="flex items-center bg-white justify-center rounded-3xl py-3.5 px-6 space-x-1.5">
            <span className="text-sm font-semibold leading-none text-black">Change network</span>
          </button>
        </div>
      </div>
    )
    }
  return (
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center !mt-0 backdrop-blur-sm">
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={stylesProps}
      ></div>
      <div className="flex flex-col items-center justify-center text-white text-4xl font-semibold z-[2] w-full space-y-2.5 pb-6">
        <button className="flex items-center bg-white justify-center rounded-3xl py-3.5 px-6 space-x-1.5 mt-[200px]">
          <span onClick={onOpen} className="text-sm font-semibold leading-none text-black">Connect Wallet</span>
        </button>
      </div>
      <ActivateModal handleCloseModal={onClose} openedModal={openedModal} />
    </div>
  )
}