import { useWeb3React } from "@web3-react/core";
import config from "../config";
import { Contract } from "@ethersproject/contracts";
import { CONTRACT_NAMES } from "../constants";

export const useGetContract = () => {
  const { account, provider } = useWeb3React();

  const types = {
    [CONTRACT_NAMES.BASE]: [config.contractXBASE, config.matrixXBaseAbi],
    [CONTRACT_NAMES.ROUTER]: [config.contractRouter, config.contractRouterAbi],
    [CONTRACT_NAMES.PANCAKE]: [
      config.pancakeSwapAddress,
      config.pancakeSwapAbi
    ],
    [CONTRACT_NAMES.BUSD]: [config.contractBusd, config.matrixTokenAbi],
    [CONTRACT_NAMES.PANCAKE_EXCHANGE]: [
      config.pancakeExchange,
      config.contractPancakeExchangeAbi
    ],
    [CONTRACT_NAMES.FRGX]: [config.contractFrgx, config.frgxAbi],
    [CONTRACT_NAMES.LIQUIDITY_BUSD]: [
      config.busdLiquidityPool,
      config.liquidityPoolAbi
    ],
    [CONTRACT_NAMES.LIQUIDITY_BNB]: [
      config.bnbLiquidityPool,
      config.liquidityPoolAbi
    ]
  };

  const getContract = type => {
    return new Promise(function(resolve, rejected) {
      if (types[type] && provider) {
        const contract = new Contract(
          ...types[type],
          provider?.getSigner(account).connectUnchecked() || provider
        );

        resolve(contract);
      } else {
        rejected("error init contract: " + type);
      }
    });
  };

  return {
    getContract
  };
};
