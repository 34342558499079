import React from "react";


export const Tokenomics = () => {
    return (
      <div className="w-full max-w-[1500px] px-[108px] sm:px-0">
            <div className="relative flex flex-col items-start space-y-[60px] relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] mt-[216px] sm:mt-[80px] sm:items-center sm:space-y-10 ">
                <h2 className="text-[50px] poppins text-white font-semibold uppercase sm:text-[30px] sm:text-center">Tokenomics</h2>
                    <div className="flex flex-col space-y-6 w-full sm:space-y-3">
                      <div className="flex space-x-6 w-full sm:flex-col sm:space-x-0 sm:space-y-3">
                        <div className="flex items-center justify-start pl-[68px] relative bg-white-20 w-full h-[400px] rounded-t-[60px] rounded-bl-[60px] rounded-br-[10px] overflow-hidden sm:pl-auto sm:px-4 sm:h-[189px] sm:rounded-t-3xl sm:rounded-b-lg">
                          <div className="flex flex-col space-y-3 z-[111]">
                            <span className="text-4xl inter uppercase font-medium text-white sm:text-lg sm:font-normal">NO ICO / Presale</span>
                            <span className="text-white-500 text-xl inter max-w-[456px] font-light sm:text-sm">FRGX entered the market through a fair launch, which ensured transparent conditions and equal opportunities without any whitelists, backdoors, or tokens held by owners. </span>
                          </div>
                          <img className="absolute right-0 bottom-0" src="/images/tokenomics/presale/shadow1.png"/>
                          <img className="absolute right-0 top-0" src="/images/tokenomics/presale/shadow2.png"/>
                        </div>
                        <div className="supply_bg flex flex-col justify-center space-y-10 pl-[48px] min-w-[380px] rounded-[60px] rounded-bl-[10px] rounded-br-[60px] sm:pl-4 sm:justify-start sm:items-center sm:space-x-3 sm:min-h-[148px] sm:flex-row sm:space-y-0  sm:rounded-t-lg sm:rounded-b-3xl sm:min-w-full">
                          <span className="text-[90px] sm:text-[55px]">⚡️</span>
                          <div className="flex flex-col justify-center">
                            <span className="text-4xl text-black inter uppercase font-medium leading-[50px] sm:text-lg">Current<br className="sm:hidden" /> Supply </span>
                            <span className="text-black opacity-50 font-light text-3xl leading-[42px] sm:text-lg">20,585,684 FRGX</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-6 sm:flex sm:flex-col sm:space-x-0 sm:space-y-4">
                        <div className="flex flex-col items-start justify-center space-y-2 relative maxSupply_bg rounded-l-[60px] rounded-r-[10px] h-[400px] min-w-[380px] pl-12 overflow-hidden sm:justify-start sm:flex-row sm:space-x-3 sm:rounded-t-3xl sm:rounded-b-lg sm:min-w-full sm:h-[148px] sm:items-center sm:pl-4">
                              <span className="text-[100px] z-[11] sm:text-[65px]">🧳</span>
                              <div className="flex flex-col">
                                <span className="text-white text-4xl inter uppercase font-medium z-[11] leading-[50px] sm:text-lg">Max Supply</span>
                                <span className="text-white-500 text-3xl inter font-light leading-[42px] sm:text-lg">21,000,000 FRGX</span>
                              </div>
                            <img className="absolute top-[-20px] left-[50px] z-[0]" src="/images/tokenomics/maxSupply/shadow.png"/>
                        </div>
                        <div className="flex items-center justify-center relative bg-white-20 rounded-r-[60px] rounded-l-[10px] h-[400px] w-full pl-[68px] overflow-hidden sm:flex-col sm:rounded-t-lg sm:rounded-b-3xl sm:pl-0 sm:py-8 sm:h-full">
                              <div className="flex flex-col space-y-3 z-[111] sm:pl-4">
                                <span className="text-white text-4xl inter uppercase font-medium z-[11] leading-[50px] sm:text-lg">BNB Chain</span>
                                <span className="text-white-500 text-xl inter font-light leading-[28px] max-w-[456px] sm:text-sm">FRGX works on a reliable blockchain with fast transactions and minimal fees. This ensures seamless integration with wallets and other services that operate on BNB chain.</span>
                              </div>
                              <img className="relative z-[1] right-[-10px] sm:hidden" src="/images/tokenomics/bnbChain/swap.png"/>
                              <img className="relative z-[1] hidden sm:flex mt-6" src="/images/tokenomics/bnbChain/swapMob.png"/>
                            <img className="absolute top-0 right-0 z-[0] h-full" src="/images/tokenomics/bnbChain/shadow1.png"/>
                            <img className="absolute top-0 right-0 z-[0] h-full" src="/images/tokenomics/bnbChain/shadow2.png"/>
                        </div>
                      </div>
                      <div className="flex space-x-6 w-full sm:flex-col sm:space-x-0 sm:space-y-4">
                        <div className="flex items-center justify-start pl-[68px] relative bg-white-20 w-full h-[400px] rounded-b-[60px] rounded-br-[60px] rounded-tr-[10px] overflow-hidden sm:rounded-t-3xl sm:rounded-b-lg sm:pl-4 sm:items-start sm:py-8 sm:h-[240px]">
                          <div className="flex flex-col space-y-3">
                            <span className="text-4xl inter uppercase font-medium text-white sm:text-xl">No fees</span>
                            <span className="text-white-500 text-xl inter max-w-[310px] font-light sm:text-sm">There are no commissions for buying and selling tokens. This simplifies calculations and ensures that the token will be convenient for circulation within new projects. </span>
                          </div>
                          <img className="absolute right-0 bottom-0" src="/images/tokenomics/noFees/star.png"/>
                          <img className="absolute right-0 top-0 sm:h-full" src="/images/tokenomics/noFees/shadow.png"/>
                        </div>
                        <div className="burned_bg flex flex-col justify-start pl-[48px] pt-[48px] min-w-[380px] rounded-[60px] rounded-tl-[10px] sm:space-x-3 sm:flex-row sm:items-center sm:jsutify-center  sm:rounded-t-lg sm:rounded-b-3xl sm:min-w-full sm:pl-4 sm:py-8 ">
                          <span className="text-[90px] sm:text-[50px]">🔥</span>
                          <div className="flex flex-col">
                            <span className="text-4xl text-white inter uppercase font-medium leading-[50px] sm:text-xl">Burned</span>
                            <span className="text-white-500 font-light text-3xl leading-[42px sm:text-xl">414,316 FRGX</span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
        </div>
    )
}
