import { pancakeSwapAbi } from "./contractsAbi/swap";
import { matrixTokenAbi } from "./contractsAbi/token";
import { pancakeExchangeAbi } from "./contractsAbi/pancakeExchange";
import { frgxAbi } from "./contractsAbi/frgx";
import { liquidityAbi } from "./contractsAbi/liquidityAbi";
import { matrixXBaseAbi } from "./contractsAbi/xbase";
import { contractRouterAbi } from "./contractsAbi/router";

export default {
  pancakeSwapAddress: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
  pancakeExchange: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  contractBusd: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  contractBnb: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  contractFrgx: '0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c',
  contractXBASE: "0x5acc84a3e955bdd76467d3348077d003f00ffb97",
  contractRouter: "0x33C31572868f3Df6CD2647561D4c1f0BF1Bb9C9C",
  allowedChainId: "56",

  matrixXBaseAbi: matrixXBaseAbi,
  pancakeSwapAbi: pancakeSwapAbi,
  matrixTokenAbi: matrixTokenAbi,
  contractPancakeExchangeAbi: pancakeExchangeAbi,
  liquidityPoolAbi: liquidityAbi,
  frgxAbi: frgxAbi,
  contractRouterAbi: contractRouterAbi,
};
