import React from "react";
import { Timer } from "../../../../components";

export const CustomTimer = ({ isCompletedCountdown, setIsCompletedCountdown }) => {
  const stylesProps = {
    backgroundImage: `url(/images/swap/timer/bg.svg)`,
    backgroundSize: "cover"
  };
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center !mt-0 backdrop-blur-sm">
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={stylesProps}
            ></div>
            <div className="flex flex-col items-center justify-center text-white text-4xl font-semibold z-[2] w-full">
              <img src="/images/swap/timer/timerIcon.png" alt="" />
              <div className="flex items-center justify-center bg-white-10 px-6 py-4 rounded-[24px] backdrop-blur-2xl border border-white-50 w-[70%] sm:w-[95%]">
                <Timer
                  isCompletedCountdown={isCompletedCountdown}
                  setIsCompletedCountdown={setIsCompletedCountdown}
                />
              </div>
              <span className="text-base mt-2.5 text-white-750">
                16:00 UTC, December 20, 2023
              </span>
            </div>
          </div>
  )
}